import React, { Component, Fragment } from "react";
import { Navigate } from "react-router-dom";

import { CommonService } from "../js_modules/CommonService";

import { SellToWebTopPanelComponent } from "../common/selltowebtoppanel/SellToWebTopPanelComponent";
import { SellToWebRightPanelComponent } from "../common/selltowebrightpanel/SellToWebRightPanelComponent";
import { SellToWebDropdownComponent } from "../common/selltowebdropdown/SellToWebDropdownComponent";
import { SellToWebInputComponent } from "../common/selltowebinput/SellToWebInputComponent";
import { SellToWebButtonComponent } from "../common/selltowebbutton/SellToWebButtonComponent";

export class VehicleDetailsMMRRouteComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            trims: [{ description: { trim: "Select a Trim", disabled: true, selected: true } }],
            selectedTrim: null,
            mmrColorOptions: [],
            selectedColor: null,
            odometer: "",
            isVehicleConditionClicked: false,
            showSpinner: false,
            redirectTo: "",
            makePlusModel: "",
        };

        this.onOptionDropdownChange = this.onOptionDropdownChange.bind(this);
        this.onBackButtonClick = this.onBackButtonClick.bind(this);
        this.onOdometerChange = this.onOdometerChange.bind(this);
        this.onVehicleConditionClick = this.onVehicleConditionClick.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }


    componentDidMount() {
        try {
            let homeData = CommonService.getSessionStorage("homeData");
            let vin = CommonService.getLocalStorage("HomeVINorLP");
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/vehicle-details", pageType: "Consumer", isLoggedIn: "No", properties: { vin: vin, trim: homeData?.items[0]?.description?.trim, tenantName: this.props.tenantName, siteName: this.props.siteName } });
            if (homeData) {
                CommonService.clientAzureStorageLog("VehicleDetailsMMRRouteComponent", "componentDidMount", vin, null, null, JSON.stringify(homeData), this.props.applicationInsights);

                let trimLocalStorage = CommonService.getLocalStorage("Home" + vin + "SelectedTrim");
                let trims = homeData.items;
                trims = JSON.parse(JSON.stringify(trims));
                let makePlusModel = trims[0].description.year + " " + trims[0].description.make + " " + trims[0].description.model;
                let selectedTrim = null;
                if (trims.length === 1) {
                    selectedTrim = trims[0];
                }
                else {
                    let bestMatchTrim;
                    trims.forEach((trim) => {
                        if (trim.bestMatch) {
                            bestMatchTrim = trim;
                        }
                    });
                    if (bestMatchTrim) {
                        selectedTrim = bestMatchTrim;
                    }
                    else {
                        if (trimLocalStorage) {
                            selectedTrim = trims.find(trim => trim.description.trim === trimLocalStorage);
                        }
                        else {
                            trims.unshift({ description: { trim: "Select a Trim", disabled: true, selected: true } });
                        }
                    }
                }

                this.setState({ selectedTrim: selectedTrim, trims: trims, makePlusModel: makePlusModel }, () => {
                    if (selectedTrim) {
                        this.getMMRColors();
                    }
                });
            }
        }
        catch (error) {
            console.logError(error, "VehicleDetailsMMRRouteComponent", "componentDidMount");
        }
    }

    getMMRColors() {
        let vin = CommonService.getLocalStorage("HomeVINorLP");
        try {
            this.props.applicationInsights.trackTrace({ message: "VehicleDetailsMMRRouteComponent/getMMRColors", properties: { tenantName: this.props.tenantName, siteName: this.props.siteName } });
            fetch("/VehicleDetails/GetMMRColors", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json; charset=utf-8;"
                },
                credentials: "same-origin"
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    parsedResponse.items.push({ "value": "Others" });
                    parsedResponse.items.forEach((item) => {
                        item.colorBox = CommonService.checkCSSColorSupports(item.value);
                        if (item.value === "Others") {
                            item.colorBox = "White";
                        }
                    });
                    let selectedColor = null;
                    let lsSelectedColor = CommonService.getLocalStorage("VehicleDetails" + this.state.selectedTrim?.description?.trim + "SelectedColor");
                    if (lsSelectedColor) {
                        selectedColor = parsedResponse.items.find(item => item.value === lsSelectedColor);
                    }
                    this.setState({
                        mmrColorOptions: parsedResponse.items
                    }, () => {
                        this.refreshVehicleOptions();
                    });
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/VehicleDetails/GetMMRColors", ...parsedError, tenantName: this.props.tenantName, siteName: this.props.siteName } });
                                console.logError(parsedError, "VehicleDetailsMMRRouteComponent", "getMMRColors", vin);
                            })
                            .catch(jsonParseError => {
                                console.logError(jsonParseError, "VehicleDetailsMMRRouteComponent", "getMMRColors", vin);
                            });
                    }
                });
        }
        catch (error) {
            console.logError(error, "VehicleDetailsMMRRouteComponent", "getMMRColors", vin);
        }
    }

    refreshVehicleOptions() {
        let vin = CommonService.getLocalStorage("HomeVINorLP");
        try {
            let selectedColor = null, odometer = "";
            let lsSelectedColor = CommonService.getLocalStorage("VehicleDetails" + this.state.selectedTrim?.description?.trim + "SelectedColor");
            if (lsSelectedColor) {
                selectedColor = this.state.mmrColorOptions.find(item => item.value === lsSelectedColor);
            }

            let lsOdometer = CommonService.getLocalStorage("VehicleDetails" + this.state.selectedTrim?.description?.trim + "Odometer");
            if (lsOdometer) {
                odometer = lsOdometer;
            }
            this.setState({
                selectedColor: selectedColor,
                odometer: odometer
            });
        }
        catch (error) {
            console.logError(error, "VehicleDetailsMMRRouteComponent", "refreshVehicleOptions", this.state.selectedTrim?.description?.trim + " " + vin);
        }
    }


    updateTrimChange(id, selectedOption) {
        try {
            this.setState({
                [id]: this.state.trims.find(x => x.description.trim === selectedOption.description.trim),
                isVehicleConditionClicked: false,
            });
        }
        catch (error) {
            console.logError(error, "VehicleDetailsMMRRouteComponent", "updateTrimChange", id);
        }
    }

    updateColorChange(id, selectedOption) {
        try {
            this.setState({ [id]: selectedOption });
        }
        catch (error) {
            console.logError(error, "VehicleDetailsMMRRouteComponent", "updateColorChange", id);
        }
    }

    onOptionDropdownChange(id, selectedOption) {
        try {
            switch (id) {
                case "selectedTrim":
                    this.updateTrimChange(id, selectedOption);
                    break;
                case "selectedColor":
                    this.updateColorChange(id, selectedOption);
                    break;
                default:
                    break;
            }
        }
        catch (error) {
            console.logError(error, "VehicleDetailsMMRRouteComponent", "onOptionDropdownChange", id);
        }
    }

    onOdometerChange(id, value) {
        try {
            let formattedValue = value.replaceAll(",", "").replace(/[^0-9]/g, "");
            this.setState({
                [id]: formattedValue
            });
        }
        catch (error) {
            console.logError(error, "VehicleDetailsMMRRouteComponent", "onOdometerChange", id);
        }
    }

    handleKeyDown(event) {
        if (event.keyCode === 13) {
            event.preventDefault();
            this.onVehicleConditionClick();
        }
    }

    onBackButtonClick() {
        window.history.go(-1);
    }

    onVehicleConditionClick() {
        try {
            let vin = CommonService.getLocalStorage("HomeVINorLP");
            let offerSession = CommonService.getSessionStorage("offerSession");
            let inputsValid = this.state.selectedTrim?.description.trim !== "Select a Trim" && this.state.selectedColor !== "Select a Paint Color" && this.state.odometer;

            if (inputsValid) {

                this.setState({ showSpinner: true });
                let inputData = {
                    Vin: vin
                };
                this.props.applicationInsights.trackTrace({ message: "VehicleDetailsRouteComponent/onVehicleConditionClick", properties: { ...inputData, tenantName: this.props.tenantName, siteName: this.props.siteName } });
                fetch("/VehicleEquipment/GetAutoCheckData", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json; charset=utf-8;"
                    },
                    body: JSON.stringify(inputData),
                    credentials: "same-origin"
                })
                    .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                    .then(parsedResponse => {
                        if (parsedResponse.errors || parsedResponse.status === "Error") {
                            CommonService.clearLocalStorage(vin);
                            this.setState({
                                redirectTo: "/unique-vehicle",
                                showSpinner: false
                            });
                        }
                        else {
                            let vehicleDetailsData = {
                                trims: this.state.trims,
                                selectedTrim: this.state.selectedTrim,
                                selectedColor: this.state.selectedColor,
                                exteriorColorOptions: this.state.mmrColorOptions,
                                odometer: this.state.odometer,
                                vin: vin,
                                licensePlate: offerSession?.vehicleDetail?.licensePlate === undefined ? "" : offerSession.vehicleDetail.licensePlate,
                                licensePlateState: offerSession?.vehicleDetail?.licensePlateState === undefined ? "" : offerSession.vehicleDetail.licensePlateState,
                                makePlusModel: this.state.makePlusModel,
                            };
                            let autocheckData = {
                                autocheckResponse: parsedResponse.autocheckResponse,
                                accidentCount: parsedResponse.accidentCount,
                                ownerCount: parsedResponse.ownerCount,
                                accidentDetails: parsedResponse.accidentDetails,
                                useAutocheck: parsedResponse.useAutocheck
                            }
                            vehicleDetailsData["autocheckData"] = autocheckData;
                            CommonService.setSessionStorage("vehicleDetailsData", vehicleDetailsData);


                            offerSession.vehicleDetail.year = this.state.selectedTrim.description.year;
                            offerSession.vehicleDetail.make = this.state.selectedTrim.description.make;
                            offerSession.vehicleDetail.model = this.state.selectedTrim.description.model;
                            offerSession.vehicleDetail.trim = this.state.selectedTrim.description.trim;
                            offerSession.vehicleDetail.odometer = this.state.odometer;
                            offerSession.vehicleDetail.colorSimpleTitle = this.state.selectedColor.value;

                            offerSession.vehicleHistory = {};
                            offerSession.vehicleHistory.owners = parsedResponse.ownerCount;
                            offerSession.vehicleHistory.accidentCount = parsedResponse.accidentCount;
                            offerSession.vehicleHistory.autocheckResponse = parsedResponse.autocheckResponse;
                            offerSession.vehicleHistory.useAutocheck = parsedResponse.useAutocheck;
                            CommonService.setSessionStorage("offerSession", offerSession);

                            CommonService.setLocalStorage("Home" + vin + "SelectedTrim", this.state.selectedTrim?.description?.trim);
                            CommonService.setLocalStorage("VehicleDetails" + this.state.selectedTrim?.description?.trim + "SelectedColor", this.state.selectedColor?.value);
                            CommonService.setLocalStorage("VehicleDetails" + this.state.selectedTrim?.description?.trim + "Odometer", this.state.odometer);

                            if (parsedResponse.accidentCount.toString() === "0") {
                                this.setState({ redirectTo: "/vehicle-condition" });
                            }
                            else {
                                this.setState({ redirectTo: "/vehicle-history" });
                            }
                        }
                    })
                    .catch(notOKResponse => {
                        if (notOKResponse.status === 500) {
                            notOKResponse.json()
                                .then(parsedError => {
                                    this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/VehicleDetails/GetAutoCheckData", ...inputData, ...parsedError, tenantName: this.props.tenantName, siteName: this.props.siteName } });
                                    console.logError(parsedError, "VehicleDetailsRouteComponent", "onVehicleConditionClick");
                                })
                                .catch(jsonParseError => {
                                    console.logError(jsonParseError, "VehicleDetailsRouteComponent", "onVehicleConditionClick");
                                });
                        }
                    });
            }

            this.setState({
                isVehicleConditionClicked: true
            });
        }
        catch (error) {
            console.logError(error, "VehicleDetailsMMRRouteComponent", "onVehicleConditionClick", this.state.selectedTrim?.description.trim);
        }
    }

    render() {
        if (this.state.redirectTo) {
            return <Navigate to={this.state.redirectTo} />;
        }

        let vin = CommonService.getLocalStorage("HomeVINorLP");
        let bodyHtml = CommonService.getSiteAttributeValue(this.props.siteAttributes, "Vehicle Details", "Default", "Body Html")?.Value ?? "";
        return <Fragment>
            <SellToWebTopPanelComponent imageUrl={this.state.imageUrl} makePlusModel={this.state.makePlusModel} trim={this.state.selectedTrim && this.state.selectedTrim?.description?.trim !== "Select a Trim" ? this.state.selectedTrim?.description?.trim : "–"} />
            <section className="consumer-page">
                <section className="consumer-main">
                    <section className="ac-site-body ac-vehicledetails-body" dangerouslySetInnerHTML={{ __html: bodyHtml }} />
                    <section className="consumer-vehicledetails-form">
                        <SellToWebDropdownComponent id="selectedTrim" className="consumer-vehicledetails-form-trim" label="Trim" isChecked={(this.state.selectedTrim && this.state.selectedTrim?.description?.trim !== "Select a Trim")} data={this.state.trims} itemKey="description.trim" value={this.state.selectedTrim !== null ? this.state.selectedTrim?.description?.trim : "Select a Trim"} onSelectChange={this.onOptionDropdownChange} isError={this.state.isVehicleConditionClicked && this.state.selectedTrim === null}></SellToWebDropdownComponent>
                        <SellToWebDropdownComponent id="selectedColor" className={"consumer-vehicledetails-form-oem" + (!this.state.selectedTrim ? " consumer-vehicledetails-form-opacity" : "")} classNameItem="app-fw-500" label="Exterior Color" isChecked={(this.state.selectedColor && this.state.selectedColor !== "Select a Paint Color")} disabled={!this.state.selectedTrim} data={this.state.mmrColorOptions} itemKey="value" value={this.state.selectedColor !== null ? this.state.selectedColor?.value : "Select a Paint Color"} onSelectChange={this.onOptionDropdownChange} isError={this.state.isVehicleConditionClicked && !this.state.selectedColor} colorBox={this.state.selectedColor ? CommonService.checkCSSColorSupports(this.state.selectedColor?.value) : null}></SellToWebDropdownComponent>
                        <SellToWebInputComponent id="odometer" appendText="Miles" isChecked={this.state.odometer} className={!this.state.selectedTrim ? " consumer-vehicledetails-form-opacity" : ""} disabled={!this.state.selectedTrim} value={this.state.odometer ? CommonService.formatMoney(this.state.odometer) : ""} placeholder="Enter Your Current Mileage" onTextChange={this.onOdometerChange} onKeyDown={this.handleKeyDown} imagePath="images/miles-input.svg" isError={this.state.isVehicleConditionClicked && !this.state.odometer} />
                        <div className="d-flex justify-content-end">
                            <div style={{ "cursor": "pointer", "fontSize": "12px", "fontWeight": "400", "textDecoration": "underline", "margin": "auto 30px" }} onClick={(event => { window.history.go(-1); })}>
                                <span className="bi bi-chevron-left" style={{ "marginRight": "5px", "verticalAlign": "middle" }} />
                                Go Back
                            </div>
                            <SellToWebButtonComponent onClick={this.onVehicleConditionClick} showSpinner={this.state.showSpinner} value="Vehicle Condition" />
                        </div>
                    </section>
                </section>
                <aside className="consumer-summary">
                    <SellToWebRightPanelComponent
                        vin={vin} makePlusModel={this.state.makePlusModel} valuationMethod={"MMR"}
                        imageUrl={this.state.imageUrl} differentColorShown={this.state.differentColorShown} showNoImageAvailable={this.state.showNoImageAvailable} odometer={CommonService.formatMoney(this.state.odometer)}
                        colorBox={this.state.selectedColor?.colorBox ? CommonService.checkCSSColorSupports(this.state.selectedColor?.colorBox) : null}
                        colorName={this.state.selectedColor ? this.state.selectedColor.value : ""}
                        trim={this.state.selectedTrim && this.state.selectedTrim?.description?.trim !== "Select a Trim" ? this.state.selectedTrim?.description?.trim : "–"}
                        onMissingImage={this.onMissingImage} siteAttributes={this.props.siteAttributes} applicationInsights={this.state.applicationInsights}
                    />
                </aside>
            </section>
        </Fragment>;
    }
}