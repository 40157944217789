import React, { Component, Fragment } from "react";
import moment from "moment";

import { CommonService } from "../js_modules/CommonService";

import { SellToWebAdminPageComponent } from "../common/selltowebpage/SellToWebAdminPageComponent";
import { SellToWebTableColumnComponent } from "../common/selltowebtable/SellToWebTableColumnComponent";
import { SellToWebTableRowComponent } from "../common/selltowebtable/SellToWebTableRowComponent";
import { SellToWebTableComponent } from "../common/selltowebtable/SellToWebTableComponent";
import { SellToWebAdminModalDialogComponent } from "../common/selltowebmodaldialog/SellToWebAdminModalDialogComponent";
import { SellToWebModalDialogHeaderButtonComponent } from "../common/selltowebmodaldialog/SellToWebModalDialogHeaderButtonComponent";
import { SellToWebAdminTextAreaComponent } from "../common/selltowebtextarea/SellToWebAdminTextAreaComponent";

import { TenantOffersQueryComponent } from "./TenantOffersQueryComponent";
import { SellToWebSpinnerComponent } from "../common/selltowebspinner/SellToWebSpinnerComponent";

export class TenantOffersRouteComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingData: false,
            tenants: null,
            siteDomain: "",
            tableTitle: "",
            tableData: [],
            paintTable: false,
            showRawViewPopup: false,
            rawViewTitle: "",
            rawViewData: null,
            selectedValuationMethod: ""
        };

        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onViewOfferDetailCallback = this.onViewOfferDetailCallback.bind(this);
        this.onToggleViewDetails = this.onToggleViewDetails.bind(this);
        this.onRawViewClick = this.onRawViewClick.bind(this);
        this.onCancelRawView = this.onCancelRawView.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);
        this.onCopyRawView = this.onCopyRawView.bind(this);
        this.onCopyColumnClick = this.onCopyColumnClick.bind(this);
        this.onOfferNumberColumnCallback = this.onOfferNumberColumnCallback.bind(this);
        this.onVinColumnCallback = this.onVinColumnCallback.bind(this);
        this.onEmailColumnCallback = this.onEmailColumnCallback.bind(this);

        props.onPageChanged({
            pageTitle: this.pageTitle
        });
    }

    pageTitle = "Offers";

    componentDidMount() {
        try {
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/Tenant/Offers", pageType: "Consumer/Admin", isLoggedIn: "Yes", properties: { logon: this.props.logon } });
            CommonService.clientAzureStorageLog("TenantOffersRouteComponent", "componentDidMount", null, null, null, null, this.props.applicationInsights);
            this.getTenants();
        }
        catch (error) {
            console.logError(error, "TenantOffersRouteComponent", "componentDidMount");
        }
    }

    getTenants() {
        try {
            this.setState({ isLoadingData: true });
            this.props.applicationInsights.trackTrace({ message: "TenantOffersRouteComponent/getTenants", properties: { logon: this.props.logon } });
            fetch("/TenantOffers/GetTenants", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json; charset=utf-8;"
                },
                credentials: "same-origin"
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        isLoadingData: false,
                        tenants: parsedResponse
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/TenantOffers/GetTenants", ...parsedError, logon: this.props.logon } });
                                console.logError(parsedError, "TenantOffersRouteComponent", "getTenants");
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.logError(jsonParseError, "TenantOffersRouteComponent", "getTenants");
                            });
                    }
                });
        }
        catch (error) {
            console.logError(error, "TenantOffersRouteComponent", "getTenants");
        }
    }

    onGetReportClick(selectedTenant, selectedSite, startOfferDate, endOfferDate, vin, email, selectedValuationMethod, showExpired) {
        try {
            this.setState({ isLoadingData: true, siteDomain: selectedSite.siteDomain });
            let inputData = {
                siteGuid: selectedSite.guid,
                startOfferDate: startOfferDate ? CommonService.formatServerDateTime(startOfferDate.toString()) : null,
                endOfferDate: endOfferDate ? CommonService.formatServerDateTime(endOfferDate.toString()) : null,
                vin: vin,
                email: email,
                valuationMethod: selectedValuationMethod
            };
            this.props.applicationInsights.trackTrace({ message: "TenantOffersRouteComponent/onGetReportClick", properties: { ...inputData, logon: this.props.logon } });
            fetch("/TenantOffers/GetOffers", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData),
                credentials: "same-origin"
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    let tableData = parsedResponse.table;

                    let currentDate = moment();
                    tableData.forEach((responseItem, index) => {
                        if (responseItem.source === "DGDGDash") {
                            responseItem.source_cellClassName = "admin-offers-source";
                        }
                        if (responseItem.status === "Confirmed") {
                            responseItem.rowClassName = "admin-offers-confirm";
                        }
                        else if (responseItem.status === "Warn") {
                            responseItem.rowClassName = "admin-offers-warn";
                        }
                        else if (responseItem.status === "Error") {
                            responseItem.rowClassName = "admin-offers-error";
                        }

                        let expirationDate = moment(responseItem.expirationDate);
                        if (currentDate > expirationDate) {
                            responseItem.rowClassName = "admin-offers-expired";
                            responseItem.isExpired = true;
                        }
                        else {
                            responseItem.isExpired = false;
                        }
                    });

                    if (!showExpired) {
                        tableData = tableData.filter(responseItem => !responseItem.isExpired);
                    }

                    this.setState({
                        isLoadingData: false,
                        selectedValuationMethod: selectedValuationMethod,
                        tableData: CommonService.addOrdinalColumn(tableData),
                        tableTitle: selectedTenant.name + " - " + selectedSite.siteName,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        tableTitle: "",
                        tableData: [],
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/TenantOffers/GetOffers", ...inputData, ...parsedError, logon: this.props.logon } });
                                console.logError(parsedError, "TenantOffersRouteComponent", "onGetReportClick");
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.logError(jsonParseError, "TenantOffersRouteComponent", "onGetReportClick");
                            });
                    }
                });
        }
        catch (error) {
            console.logError(error, "TenantOffersRouteComponent", "onGetReportClick");
        }
    }

    getVehicleConditionScore(rowData, vehicleCondition) {
        let score = vehicleCondition === "Like New" ? rowData.offer_detail.offer.calculationVariable.value.gradeLikeNewConditionValue
            : vehicleCondition === "Very Good" ? rowData.offer_detail.offer.calculationVariable.value.gradeVeryGoodConditionValue
                : vehicleCondition === "Acceptable" ? rowData.offer_detail.offer.calculationVariable.value.gradeAcceptableConditionValue
                    : rowData.offer_detail.offer.calculationVariable.value.gradeMajorIssuesConditionValue;

        if (this.state.selectedValuationMethod === "KBB") {
            score = vehicleCondition === "Like New" ? rowData.offer_detail.offer.calculationVariable.value.excellentCondition
                : vehicleCondition === "Very Good" ? rowData.offer_detail.offer.calculationVariable.value.veryGoodCondition
                    : vehicleCondition === "Acceptable" ? rowData.offer_detail.offer.calculationVariable.value.goodCondition
                        : rowData.offer_detail.offer.calculationVariable.value.fairCondition;
        }

        return vehicleCondition + " (" + score + ")";
    }

    onViewOfferDetailCallback(rowData) {
        let engine, transmission, driveTrain, exteriorColor;
        let nonTypicalOptions = "";
        let kbbMileageAdjustmentValue = 0, accidentDeduction = 0, keyDeduction = 0, generalAdjustment = 0, maxMileageAdjustment = 0;
        if (rowData.offer_detail) {
            if (rowData.offer_detail.vehicleDetail && rowData.offer_detail.vehicleDetail.vehicleOptions) {
                engine = rowData.offer_detail.vehicleDetail.vehicleOptions.filter(vo => vo.categoryGroup === "Engine")[0];
                transmission = rowData.offer_detail.vehicleDetail.vehicleOptions.filter(vo => vo.categoryGroup === "Transmission")[0];
                driveTrain = rowData.offer_detail.vehicleDetail.vehicleOptions.filter(vo => vo.categoryGroup === "Drivetrain")[0];
                exteriorColor = rowData.offer_detail.vehicleDetail.vehicleOptions.filter(vo => vo.categoryGroup === "Exterior Color")[0];

                rowData.offer_detail.vehicleDetail.vehicleOptions.forEach((vehicleOption, vehicleIndex) => {
                    if (vehicleOption.isTypical === false) {
                        nonTypicalOptions += vehicleOption.optionName + " (" + vehicleOption.categoryGroup + "), ";
                    }
                });

                if (nonTypicalOptions.length > 0) {
                    nonTypicalOptions = nonTypicalOptions.trim().slice(0, -1);
                }
            }

            if (rowData.offer_detail.offer) {
                kbbMileageAdjustmentValue = rowData.offer_detail.offer.kbbMileageAdjustmentValue;
                accidentDeduction = rowData.offer_detail.offer.accidentDeduction;
                keyDeduction = rowData.offer_detail.offer.keyDeduction;
                generalAdjustment = rowData.offer_detail.offer.generalAdjustment;
                maxMileageAdjustment = rowData.offer_detail.offer.maxMileageAdjustment;
            }
        }

        return <Fragment>
            <div className="d-flex flex-row align-items-center flex-wrap">
                <div className="form-row">
                    <button type="button" className="btn btn-link" onClick={(event) => this.onToggleViewDetails(event, rowData)}>Detail
                        {
                            rowData.showViewDetails
                                ? <span className="far fa-sort-up admin-icon" />
                                : <span className="far fa-sort-down admin-icon" />
                        }
                    </button>
                    <a className="btn btn-link admin-offers-salesforce-link" target="_blank" rel="noreferrer" href={rowData.offerUrl ? rowData.offerUrl : "https://" + this.state.siteDomain + "/offer?offernumber=" + rowData.offerNumber}>
                        Offer
                    </a>
                    <button type="button" className="btn btn-link" onClick={(event) => this.onRawViewClick(event, rowData)}>
                        Raw View
                    </button>
                    <a className={"btn btn-link admin-offers-salesforce-link" + (rowData.crmId ? "" : " disabled")} target="_blank" rel="noreferrer" href={"https://delgrandedealergroup.lightning.force.com/lightning/r/Lead/" + rowData.crmId + "/view"}>
                        CRM
                    </a>
                </div>
            </div>
            <br />
            {
                rowData.showViewDetails
                    ? rowData.offer_detail
                        ? <table className="admin-offers-detail">
                            <thead>
                                <tr>
                                    <th style={{ "width": "9%" }}></th>
                                    <th style={{ "width": "15%" }}></th>
                                    <th style={{ "width": "8%" }}></th>
                                    <th style={{ "width": "10%" }}></th>
                                    <th style={{ "width": "10%" }}></th>
                                    <th style={{ "width": "8%" }}></th>
                                    <th style={{ "width": "5%" }}></th>
                                    <th style={{ "width": "12%" }}></th>
                                    <th style={{ "width": "11%" }}></th>
                                    <th style={{ "width": "12%" }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colSpan="2" className="app-fw-700 admin-offers-detail-header">Vehicle Details</td>
                                    <td colSpan="2" className="app-fw-700 admin-offers-detail-header">AutoCheck Details</td>
                                    <td colSpan="2" className="app-fw-700 admin-offers-detail-header">Vehicle Condition</td>
                                    <td colSpan="2" className="app-fw-700 admin-offers-detail-header">Customer Information</td>
                                    <td colSpan="2" className="app-fw-700 admin-offers-detail-header">Offer</td>
                                </tr>
                                <tr>
                                    <td className="app-fw-700">Offer Number:</td>
                                    <td>
                                        <div className="d-flex flex-row align-items-center flex-wrap">
                                            <span className="admin-offers-detail-show-ellipsis" title={rowData.offer_detail.header.offerNumber}>{rowData.offer_detail.header.offerNumber}</span>
                                            <button type="button" className="btn btn-link admin-offers-copy-btn" onClick={(event) => this.onCopyColumnClick(event, rowData.offer_detail.header.offerNumber)}>
                                                <span className="far fa-copy" />
                                            </button>
                                        </div>
                                    </td>
                                    <td className="app-fw-700">Owner:</td>
                                    <td>{rowData.offer_detail.vehicleHistory ? rowData.offer_detail.vehicleHistory.owners : ""}</td>
                                    {
                                        this.state.selectedValuationMethod === "KBB"
                                            ? <Fragment>
                                                <td className="app-fw-700">Paint and Body:</td>
                                                <td>{rowData.offer_detail.vehicleCondition ? this.getVehicleConditionScore(rowData, rowData.offer_detail.vehicleCondition.paintBody) : ""}</td>
                                            </Fragment>
                                            : <Fragment>
                                                <td className="app-fw-700">Exterior Condition</td>
                                                <td>{rowData.offer_detail.vehicleCondition ? this.getVehicleConditionScore(rowData, rowData.offer_detail.vehicleCondition.exterior) : ""}</td>
                                            </Fragment>
                                    }
                                    <td className="app-fw-700">First:</td>
                                    <td>{rowData.offer_detail.customerInfo ? rowData.offer_detail.customerInfo.first : ""}</td>
                                    {
                                        this.state.selectedValuationMethod === "KBB"
                                            ? <Fragment>
                                                <td className="app-fw-700">Typical Listing Price:</td>
                                                <td>{rowData.offer_detail.offer ? CommonService.formatCurrency_2(rowData.offer_detail.offer.typicalListingPrice) : ""}</td>
                                            </Fragment>
                                            : <Fragment>
                                                <td className="app-fw-700">Average MMR Wholesale</td>
                                                <td>{0}</td>
                                            </Fragment>
                                    }
                                </tr>
                                <tr>
                                    <td className="app-fw-700">Year:</td>
                                    <td>{rowData.offer_detail.vehicleDetail ? rowData.offer_detail.vehicleDetail.year : ""}</td>
                                    <td className="app-fw-700">Accidents:</td>
                                    <td>{rowData.offer_detail.vehicleHistory ? rowData.offer_detail.vehicleHistory.accidentCount : ""}</td>
                                    {
                                        this.state.selectedValuationMethod === "KBB"
                                            ? <Fragment>
                                                <td className="app-fw-700">Windows and Lights:</td>
                                                <td>{rowData.offer_detail.vehicleCondition ? this.getVehicleConditionScore(rowData, rowData.offer_detail.vehicleCondition.windowsLights) : ""}</td>
                                            </Fragment>
                                            : <Fragment>
                                                <td className="app-fw-700">Interior Condition</td>
                                                <td>{rowData.offer_detail.vehicleCondition ? this.getVehicleConditionScore(rowData, rowData.offer_detail.vehicleCondition.interior) : ""}</td>
                                            </Fragment>
                                    }
                                    <td className="app-fw-700">Last:</td>
                                    <td>{rowData.offer_detail.customerInfo ? rowData.offer_detail.customerInfo.last : ""}</td>
                                    {
                                        this.state.selectedValuationMethod === "KBB"
                                            ? <Fragment>
                                                <td className="app-fw-700">Low Value:</td>
                                                <td>{rowData.offer_detail.offer ? CommonService.formatCurrency_2(rowData.offer_detail.offer.lowValue) : ""}</td>
                                            </Fragment>
                                            : <Fragment>
                                                <td className="app-fw-700">Adjusted MMR Wholesale</td>
                                                <td>{rowData.offer_detail.offer ? CommonService.formatCurrency_2(rowData.offer_detail.offer.wholesaleAveragePrice) : ""}</td>
                                            </Fragment>
                                    }
                                </tr>
                                <tr>
                                    <td className="app-fw-700">Make:</td>
                                    <td>{rowData.offer_detail.vehicleDetail ? rowData.offer_detail.vehicleDetail.make : ""}</td>
                                    <td className="app-fw-700">Accident Response:</td>
                                    <td>{rowData.offer_detail.vehicleHistory ? rowData.offer_detail.vehicleHistory.accidentResponse : ""}</td>
                                    {
                                        this.state.selectedValuationMethod === "KBB"
                                            ? <Fragment>
                                                <td className="app-fw-700">Wheels and Tires:</td>
                                                <td>{rowData.offer_detail.vehicleCondition ? this.getVehicleConditionScore(rowData, rowData.offer_detail.vehicleCondition.wheelsTires) : ""}</td>

                                            </Fragment>
                                            : <Fragment>
                                                <td className="app-fw-700">Mechanical Condition</td>
                                                <td>{rowData.offer_detail.vehicleCondition ? this.getVehicleConditionScore(rowData, rowData.offer_detail.vehicleCondition.mechanical) : ""}</td>
                                            </Fragment>
                                    }
                                    <td className="app-fw-700">Email:</td>
                                    <td>
                                        {
                                            rowData.offer_detail.customerInfo?.email
                                                ? <Fragment>
                                                    {rowData.offer_detail.customerInfo.email}
                                                    < button type="button" className="btn btn-link admin-offers-copy-btn" onClick={(event) => this.onCopyColumnClick(event, rowData.offer_detail.customerInfo.email)}>
                                                        <span className="far fa-copy" />
                                                    </button>
                                                </Fragment>
                                                : ""
                                        }
                                    </td>
                                    {
                                        this.state.selectedValuationMethod === "KBB"
                                            ? <Fragment>
                                                <td className="app-fw-700">High Value:</td>
                                                <td>{rowData.offer_detail.offer ? CommonService.formatCurrency_2(rowData.offer_detail.offer.highValue) : ""}</td>
                                            </Fragment>
                                            : <Fragment>
                                                <td className="app-fw-700">Average Grade</td>
                                                <td>{rowData.offer_detail.offer ? CommonService.formatCurrency_2(rowData.offer_detail.offer.averageGrade) : ""}</td>
                                            </Fragment>
                                    }
                                </tr>
                                <tr>
                                    <td className="app-fw-700">Model:</td>
                                    <td>{rowData.offer_detail.vehicleDetail ? rowData.offer_detail.vehicleDetail.model : ""}</td>
                                    <td className="app-fw-700">Issue Categories:</td>
                                    <td title={rowData.offer_detail.offer ? (rowData.offer_detail.offer.issueCategories ? rowData.offer_detail.offer.issueCategories.join(", ") : "") : ""}>{rowData.offer_detail.offer ? (rowData.offer_detail.offer.issueCategories ? rowData.offer_detail.offer.issueCategories.join(", ") : "") : ""}</td>
                                    {
                                        this.state.selectedValuationMethod === "KBB"
                                            ? <Fragment>
                                                <td className="app-fw-700">Upholstery:</td>
                                                <td>{rowData.offer_detail.vehicleCondition ? this.getVehicleConditionScore(rowData, rowData.offer_detail.vehicleCondition.upholstery) : ""}</td>
                                            </Fragment>
                                            : <Fragment>
                                                <td className="app-fw-700">Tire Condition</td>
                                                <td>{rowData.offer_detail.vehicleCondition ? rowData.offer_detail.vehicleCondition.tire : ""}</td>
                                            </Fragment>
                                    }
                                    <td className="app-fw-700">Phone:</td>
                                    <td>{rowData.offer_detail.customerInfo ? CommonService.formatPhoneNumber(rowData.offer_detail.customerInfo.phone) : ""}</td>
                                    {
                                        this.state.selectedValuationMethod === "KBB"
                                            ? <Fragment>
                                                <td className="app-fw-700">High to Low Diff:</td>
                                                <td>{rowData.offer_detail.offer ? CommonService.formatCurrency_2(rowData.offer_detail.offer.highValue - rowData.offer_detail.offer.lowValue) : ""}</td>
                                            </Fragment>
                                            : <Fragment>
                                                <td className="app-fw-700">General Adjustment</td>
                                                <td>{rowData.offer_detail.offer ? CommonService.formatCurrency_2(rowData.offer_detail.offer.generalAdjustment) : ""}</td>
                                            </Fragment>
                                    }
                                </tr>
                                <tr>
                                    <td className="app-fw-700">License Plate:</td>
                                    <td>{rowData.offer_detail.vehicleDetail ? rowData.offer_detail.vehicleDetail.licensePlate : ""}</td>
                                    <td colSpan="2" />
                                    {
                                        this.state.selectedValuationMethod === "KBB"
                                            ? <Fragment>
                                                <td className="app-fw-700">Interior Equipments:</td>
                                                <td>{rowData.offer_detail.vehicleCondition ? this.getVehicleConditionScore(rowData, rowData.offer_detail.vehicleCondition.interiorEquipment) : ""}</td>
                                            </Fragment>
                                            : <Fragment>
                                                <td className="app-fw-700">Windshield Condition</td>
                                                <td>{rowData.offer_detail.vehicleCondition ? rowData.offer_detail.vehicleCondition.windshield : ""}</td>
                                            </Fragment>
                                    }
                                    <td className="app-fw-700">Zip:</td>
                                    <td>{
                                        rowData.offer_detail.customerInfo?.zip
                                            ? <Fragment>
                                                {rowData.offer_detail.customerInfo.zip}
                                                <button type="button" className="btn btn-link admin-offers-copy-btn" onClick={(event) => this.onCopyColumnClick(event, rowData.offer_detail.customerInfo.zip)}>
                                                    <span className="far fa-copy" />
                                                </button>
                                            </Fragment>
                                            : ""
                                    }
                                    </td>
                                    {
                                        this.state.selectedValuationMethod === "KBB"
                                            ? <Fragment>
                                                <td className="app-fw-700">Mileage Adjustment:</td>
                                                <td className={kbbMileageAdjustmentValue < 0 ? "text-danger" : ""}>
                                                    {CommonService.formatCurrency_2(kbbMileageAdjustmentValue)}
                                                </td>
                                            </Fragment>
                                            : <Fragment>
                                                <td className="app-fw-700">Accident Adjustment</td>
                                                <td>{rowData.offer_detail.offer ? CommonService.formatCurrency_2(rowData.offer_detail.offer.accidentDeduction) : ""}</td>
                                            </Fragment>
                                    }
                                </tr>
                                <tr>
                                    <td className="app-fw-700">License Plate State:</td>
                                    <td>{rowData.offer_detail.vehicleDetail ? rowData.offer_detail.vehicleDetail.licensePlateState : ""}</td>
                                    <td colSpan="2" />
                                    {
                                        this.state.selectedValuationMethod === "KBB"
                                            ? <Fragment>
                                                <td className="app-fw-700">Mechanical:</td>
                                                <td>{rowData.offer_detail.vehicleCondition ? this.getVehicleConditionScore(rowData, rowData.offer_detail.vehicleCondition.mechanical) : ""}</td>
                                            </Fragment>
                                            : <td colSpan="2" />
                                    }
                                    <td colSpan="2" />
                                    {
                                        this.state.selectedValuationMethod === "KBB"
                                            ? <Fragment>
                                                <td className="app-fw-700">Average Condition Factor:</td>
                                                <td>{rowData.offer_detail.offer ? CommonService.formatNumber_2(rowData.offer_detail.offer.conditionFactor) : ""}</td>
                                            </Fragment>
                                            : <Fragment>
                                                <td className="app-fw-700">Tire Adjustment</td>
                                                <td>{rowData.offer_detail.offer ? CommonService.formatCurrency_2(rowData.offer_detail.offer.tireAdjustment) : ""}</td>
                                            </Fragment>
                                    }
                                </tr>
                                <tr>
                                    <td className="app-fw-700">Trim:</td>
                                    <td>{rowData.offer_detail.vehicleDetail ? rowData.offer_detail.vehicleDetail.trim : ""}</td>
                                    <td colSpan="2" />
                                    {
                                        this.state.selectedValuationMethod === "KBB"
                                            ? <Fragment>
                                                <td className="app-fw-700">Keys:</td>
                                                <td>{rowData.offer_detail.vehicleCondition ? rowData.offer_detail.vehicleCondition.keys : ""}</td>
                                            </Fragment>
                                            : <td colSpan="2" />
                                    }
                                    <td colSpan="2" className="app-fw-700 admin-offers-detail-header">Calculation Configuration</td>
                                    {
                                        this.state.selectedValuationMethod === "KBB"
                                            ? <Fragment>
                                                <td className="app-fw-700">Base Offer:</td>
                                                <td>{rowData.offer_detail.offer ? CommonService.formatCurrency_2(rowData.offer_detail.offer.baseOfferRange) : ""}</td>
                                            </Fragment>
                                            : <Fragment>
                                                <td className="app-fw-700">Windshield Adjustment</td>
                                                <td>{rowData.offer_detail.offer ? CommonService.formatCurrency_2(rowData.offer_detail.offer.windShieldAdjustment) : ""}</td>
                                            </Fragment>
                                    }
                                </tr>
                                <tr>
                                    {
                                        this.state.selectedValuationMethod === "KBB"
                                            ? <Fragment>
                                                <td className="app-fw-700">Engine:</td>
                                                <td>{engine ? decodeURIComponent(engine.optionName) : ""}</td>
                                            </Fragment>
                                            : <Fragment>
                                                <td className="app-fw-700">Color:</td>
                                                <td>{rowData.offer_detail.vehicleDetail.colorSimpleTitle ? rowData.offer_detail.vehicleDetail.colorSimpleTitle : ""}</td>
                                            </Fragment>
                                    }

                                    <td colSpan="2" />
                                    <td colSpan="2" />
                                    <td className="app-fw-700">Calculation Rule:</td>
                                    <td>{rowData.offer_detail.offer.calculationVariable.key.make
                                        ? rowData.offer_detail.offer.calculationVariable.key.make + " " : "(any Make) "}{rowData.offer_detail.offer.calculationVariable.key.year
                                            ? rowData.offer_detail.offer.calculationVariable.key.year + " " : "(any Year) "}{rowData.offer_detail.offer.calculationVariable.key.model
                                                ? rowData.offer_detail.offer.calculationVariable.key.model + " " : "(any Model) "}</td>
                                    {
                                        this.state.selectedValuationMethod === "KBB"
                                            ? <Fragment>
                                                <td className="app-fw-700">Accident Deductions:</td>
                                                <td className={accidentDeduction > 0 ? "text-danger" : ""}>
                                                    {CommonService.formatCurrency_2(accidentDeduction)}
                                                </td>
                                            </Fragment>
                                            : <Fragment>
                                                <td className="app-fw-700">Offer (Rounded):</td>
                                                <td>{rowData.offer_detail.offer ? CommonService.formatCurrency_2(rowData.offer_detail.offer.offer) : ""}</td>
                                            </Fragment>
                                    }
                                </tr>
                                <tr>
                                    {
                                        this.state.selectedValuationMethod === "KBB"
                                            ? <Fragment>
                                                <td className="app-fw-700">Transmission:</td>
                                                <td>{transmission ? decodeURIComponent(transmission.optionName) : ""}</td>
                                            </Fragment>
                                            : <Fragment>
                                                <td className="app-fw-700">Odometer:</td>
                                                <td>{
                                                    rowData.offer_detail.vehicleDetail?.odometer
                                                        ? <Fragment>
                                                            {CommonService.formatNumber(rowData.offer_detail.vehicleDetail.odometer)}
                                                            <button type="button" className="btn btn-link admin-offers-copy-btn" onClick={(event) => this.onCopyColumnClick(event, rowData.offer_detail.vehicleDetail.odometer)}>
                                                                <span className="far fa-copy" />
                                                            </button>
                                                        </Fragment>
                                                        : ""
                                                }
                                                </td>
                                            </Fragment>
                                    }
                                    <td colSpan="2" />
                                    <td colSpan="2" />
                                    {
                                        this.state.selectedValuationMethod === "KBB"
                                            ? <Fragment>
                                                <td className="app-fw-700">Accident Deduction:</td>
                                                <td>{rowData.offer_detail.offer ? CommonService.formatNumber_1(rowData.offer_detail.offer.calculationVariable.value.accidentDeductionPct * 100) + "%" : ""}</td>
                                            </Fragment>
                                            : <Fragment>
                                                <td className="app-fw-700">General Adjustment</td>
                                                <td>{rowData.offer_detail.offer ? rowData.offer_detail.offer.calculationVariable.value.generalAdjustmentIsDollar ? CommonService.formatCurrency_2(rowData.offer_detail.offer.calculationVariable.value.generalAdjustmentValue) : CommonService.formatNumber_1(rowData.offer_detail.offer.calculationVariable.value.generalAdjustmentValue * 100) + "%" : ""}</td>
                                            </Fragment>
                                    }
                                    {
                                        this.state.selectedValuationMethod === "KBB"
                                            ? <Fragment>
                                                <td className="app-fw-700">Key Deduction:</td>
                                                <td className={keyDeduction > 0 ? "text-danger" : ""}>
                                                    {
                                                        CommonService.formatCurrency_2(keyDeduction)
                                                    }
                                                </td>
                                            </Fragment>
                                            : <Fragment>
                                                <td className="app-fw-700">Error Message:</td>
                                                <td title={rowData.offer_detail.offer ? rowData.offer_detail.offer.errorMessage : ""}>{rowData.offer_detail.offer ? rowData.offer_detail.offer.errorMessage : ""}</td>
                                            </Fragment>
                                    }
                                </tr>
                                <tr>
                                    {
                                        this.state.selectedValuationMethod === "KBB"
                                            ? <Fragment>
                                                <td className="app-fw-700">DriveTrain:</td>
                                                <td>{driveTrain ? decodeURIComponent(driveTrain.optionName) : ""}</td>
                                            </Fragment>
                                            : <Fragment>
                                                <td colSpan="2" />
                                            </Fragment>
                                    }

                                    <td colSpan="2" />
                                    <td colSpan="2" />
                                    {
                                        this.state.selectedValuationMethod === "KBB"
                                            ? <Fragment>
                                                <td className="app-fw-700">Missing Key Deduction:</td>
                                                <td>{rowData.offer_detail.offer ? CommonService.formatNumber_1(rowData.offer_detail.offer.calculationVariable.value.missingKeyDeduction * 100) + "%" : ""}</td>
                                            </Fragment>
                                            : <Fragment>
                                                <td className="app-fw-700">Accident Adjustment</td>
                                                <td>{rowData.offer_detail.offer ? rowData.offer_detail.offer.calculationVariable.value.accidentAdjustmentIsDollar ? CommonService.formatCurrency_2(rowData.offer_detail.offer.calculationVariable.value.accidentAdjustmentValue) : CommonService.formatNumber_1(rowData.offer_detail.offer.calculationVariable.value.accidentAdjustmentValue * 100) + "%" : ""}</td>
                                            </Fragment>
                                    }
                                    {
                                        this.state.selectedValuationMethod === "KBB"
                                            ? <Fragment>
                                                <td className="app-fw-700">General Adjustment:</td>
                                                <td className={generalAdjustment < 0 ? "text-danger" : ""}>
                                                    {CommonService.formatCurrency_2(generalAdjustment)}
                                                </td>
                                            </Fragment>
                                            : <td colSpan="2" />
                                    }
                                </tr>
                                <tr>
                                    {
                                        this.state.selectedValuationMethod === "KBB"
                                            ? <Fragment>
                                                <td className="app-fw-700">Color:</td>
                                                <td>{rowData.offer_detail.vehicleDetail.colorTitle ? rowData.offer_detail.vehicleDetail.colorTitle : (exteriorColor ? decodeURIComponent(exteriorColor.optionName) : "")}</td>
                                            </Fragment>
                                            : <td colSpan="2" />
                                    }
                                    <td colSpan="2" />
                                    <td colSpan="2" />
                                    {
                                        this.state.selectedValuationMethod === "KBB"
                                            ? <Fragment>
                                                <td className="app-fw-700">General Adjustment:</td>
                                                <td>{rowData.offer_detail.offer ? CommonService.formatNumber_1(rowData.offer_detail.offer.calculationVariable.value.generalAdjustmentPct * 100) + "%" : ""}</td>
                                            </Fragment>
                                            : <Fragment>
                                                <td className="app-fw-700">Tire Adjustment</td>
                                                <td>{0}</td>
                                            </Fragment>
                                    }
                                    {
                                        this.state.selectedValuationMethod === "KBB"
                                            ? <Fragment>
                                                <td className="app-fw-700">Max Mileage Adj Deduction:</td>
                                                <td className={maxMileageAdjustment > 0 ? "text-danger" : ""}>
                                                    {CommonService.formatCurrency_2(maxMileageAdjustment)}
                                                </td>
                                            </Fragment>
                                            : <td colSpan="2" />
                                    }
                                </tr>
                                <tr>
                                    {
                                        this.state.selectedValuationMethod === "KBB"
                                            ? <Fragment>
                                                <td className="app-fw-700">Odometer:</td>
                                                <td>{
                                                    rowData.offer_detail.vehicleDetail?.odometer
                                                        ? <Fragment>
                                                            {CommonService.formatNumber(rowData.offer_detail.vehicleDetail.odometer)}
                                                            <button type="button" className="btn btn-link admin-offers-copy-btn" onClick={(event) => this.onCopyColumnClick(event, rowData.offer_detail.vehicleDetail.odometer)}>
                                                                <span className="far fa-copy" />
                                                            </button>
                                                        </Fragment>
                                                        : ""
                                                }
                                                </td>
                                            </Fragment>
                                            : <td colSpan="2" />
                                    }
                                    <td colSpan="2" />
                                    <td colSpan="2" />
                                    {
                                        this.state.selectedValuationMethod === "KBB"
                                            ? <Fragment>
                                                <td className="app-fw-700">Max Mileage Adj Deduction:</td>
                                                <td>{rowData.offer_detail.offer ? CommonService.formatNumber_1(rowData.offer_detail.offer.calculationVariable.value.maxMileageAdjustmentPct * 100) + "%" : ""}</td>
                                            </Fragment>
                                            : <Fragment>
                                                <td className="app-fw-700">Windshield Adjustment</td>
                                                <td>{0}</td>
                                            </Fragment>
                                    }
                                    {
                                        this.state.selectedValuationMethod === "KBB"
                                            ? <Fragment>
                                                <td className="app-fw-700">Offer (Rounded):</td>
                                                <td>{rowData.offer_detail.offer ? CommonService.formatCurrency_2(rowData.offer_detail.offer.offer) + (rowData.offer_detail.offer.offerTLPRatio ? " (" + CommonService.formatPercentage_1(rowData.offer_detail.offer.offerTLPRatio) + " of TLP)" : "") : ""}</td>
                                            </Fragment>
                                            : <td colSpan="2" />
                                    }
                                </tr>
                                <tr>
                                    <td colSpan="2" />
                                    <td colSpan="2" />
                                    <td colSpan="2" />
                                    {
                                        this.state.selectedValuationMethod === "KBB"
                                            ? <Fragment>
                                                <td className="app-fw-700">No Offer on Major Issues:</td>
                                                <td>{rowData.offer_detail.offer ? rowData.offer_detail.offer.calculationVariable.value.noOfferOnMajorIssues === true ? "true" : "false" : ""}</td>
                                            </Fragment>
                                            : <Fragment>
                                                <td className="app-fw-700">Enable Offer:</td>
                                                <td>{rowData.offer_detail.offer ? rowData.offer_detail.offer.calculationVariable.value.enableOfferCalculation === true ? "true" : "false" : ""}</td>
                                            </Fragment>
                                    }
                                    {
                                        this.state.selectedValuationMethod === "KBB"
                                            ? <Fragment>
                                                <td className="app-fw-700">Error Message:</td>
                                                <td title={rowData.offer_detail.offer ? rowData.offer_detail.offer.errorMessage : ""}>{rowData.offer_detail.offer ? rowData.offer_detail.offer.errorMessage : ""}</td>
                                            </Fragment>
                                            : <td colSpan="2" />
                                    }
                                </tr>
                                {
                                    this.state.selectedValuationMethod === "KBB"
                                        ? <tr>
                                            <td className="app-fw-700 text-nowrap">Vehicle Options:</td>
                                            <td colSpan="10">
                                                {nonTypicalOptions}
                                            </td>
                                        </tr>
                                        : ""
                                }

                            </tbody>
                        </table>
                        : <SellToWebSpinnerComponent showSpinner={!rowData.offer_detail} />
                    : null
            }
        </Fragment>;
    }

    onToggleViewDetails(event, rowData) {
        rowData.showViewDetails = rowData.showViewDetails ? false : true;
        this.setState({
            paintTable: true
        }, () => {
            this.setState({ paintTable: false });
        });

        try {
            if (!rowData.offer_detail) {
                let inputData = {
                    offerNumber: rowData.offerNumber
                };
                this.props.applicationInsights.trackTrace({ message: "TenantOffersRouteComponent/onToggleViewDetails", properties: { ...inputData, logon: this.props.logon } });
                fetch("/Offer/ReadOffer", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json; charset=utf-8;"
                    },
                    body: JSON.stringify(inputData),
                    credentials: "same-origin"
                })
                    .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                    .then(parsedResponse => {
                        rowData.offer_detail = parsedResponse;
                        this.setState({
                            paintTable: true
                        }, () => {
                            this.setState({ paintTable: false });
                        });
                    })
                    .catch(notOKResponse => {
                        if (notOKResponse.status === 500) {
                            notOKResponse.json()
                                .then(parsedError => {
                                    this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/Offer/ReadOffer", ...inputData, ...parsedError, logon: this.props.logon } });
                                    console.logError(parsedError, "TenantOffersRouteComponent", "onToggleViewDetails");
                                    this.props.onShowAlert("danger", parsedError);
                                })
                                .catch(jsonParseError => {
                                    console.logError(jsonParseError, "TenantOffersRouteComponent", "onToggleViewDetails");
                                });
                        }
                    });
            }
        }
        catch (error) {
            console.logError(error, "TenantOffersRouteComponent", "onToggleViewDetails");
        }
    }

    onRawViewClick(event, rowData) {
        try {
            this.setState({
                rawViewTitle: "Offer: " + rowData.offerNumber,
                showRawViewPopup: true
            });

            if (!rowData.offer_detail) {
                let inputData = {
                    offerNumber: rowData.offerNumber
                };
                this.props.applicationInsights.trackTrace({ message: "TenantOffersRouteComponent/onRawViewClick", properties: { ...inputData, logon: this.props.logon } });
                fetch("/Offer/ReadOffer", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json; charset=utf-8;"
                    },
                    body: JSON.stringify(inputData),
                    credentials: "same-origin"
                })
                    .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                    .then(parsedResponse => {
                        rowData.offer_detail = parsedResponse;
                        this.setState({
                            rawViewData: JSON.stringify(parsedResponse, null, "\t")
                        });
                    })
                    .catch(notOKResponse => {
                        if (notOKResponse.status === 500) {
                            notOKResponse.json()
                                .then(parsedError => {
                                    this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/Offer/ReadOffer", ...inputData, ...parsedError, logon: this.props.logon } });
                                    console.logError(parsedError, "TenantOffersRouteComponent", "onRawViewClick");
                                    this.props.onShowAlert("danger", parsedError);
                                })
                                .catch(jsonParseError => {
                                    console.logError(jsonParseError, "TenantOffersRouteComponent", "onRawViewClick");
                                });
                        }
                    });
            }
            else {
                this.setState({
                    rawViewData: JSON.stringify(rowData.offer_detail, null, "\t")
                });
            }
        }
        catch (error) {
            console.logError(error, "TenantOffersRouteComponent", "onRawViewClick");
        }
    }

    onCancelRawView(event) {
        try {
            this.setState({
                showRawViewPopup: false,
                rawViewTitle: "",
                rawViewData: null
            });
        }
        catch (error) {
            console.logError(error, "TenantOffersRouteComponent", "onCancelClick");
        }
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard." }, 4000);
            CommonService.clientAzureStorageLog("TenantOffersRouteComponent", "onCopyTableCallBack", null, null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.logError(error, "TenantOffersRouteComponent", "onCopyTableCallBack");
        }
    }

    onCopyRawView(event) {
        try {
            navigator.clipboard.writeText(this.state.rawViewData)
                .then(() => {
                    this.props.onShowAlert("success", { message: "Copied to Clipboard." }, 4000);
                    CommonService.clientAzureStorageLog("TenantOffersRouteComponent", "onCopyRawView", null, null, null, null, this.props.applicationInsights);
                });
        }
        catch (error) {
            console.logError(error, "TenantOffersRouteComponent", "onCopyRawView");
        }
    }

    onCopyColumnClick(event, columnValue) {
        try {
            navigator.clipboard.writeText(columnValue)
                .then(() => {
                    this.props.onShowAlert("success", { message: "Copied to Clipboard." }, 4000);
                    CommonService.clientAzureStorageLog("TenantOffersRouteComponent", "onCopyColumnClick", null, null, null, null, this.props.applicationInsights);
                });
        }
        catch (error) {
            console.logError(error, "TenantOffersRouteComponent", "onCopyColumnClick");
        }
    }

    onOfferNumberColumnCallback(rowData) {
        return <div className="d-flex flex-row align-items-center flex-wrap admin-offers-number-column">
            <span className="admin-offers-detail-show-ellipsis" title={rowData.offerNumber}>{rowData.offerNumber}</span>
            <button type="button" className="btn btn-link admin-offers-copy-btn" onClick={(event) => this.onCopyColumnClick(event, rowData.offerNumber)}>
                <span className="far fa-copy" />
            </button>
        </div>;
    }

    onVinColumnCallback(rowData) {
        return <div className="d-flex flex-row align-items-center flex-wrap" >
            <span title={rowData.vin}>{rowData.vin}</span>
            <button type="button" className="btn btn-link admin-offers-copy-btn" onClick={(event) => this.onCopyColumnClick(event, rowData.vin)}>
                <span className="far fa-copy" />
            </button>
        </div>;
    }

    onEmailColumnCallback(rowData) {
        return <div className="d-flex flex-row align-items-center flex-wrap admin-offers-email-column">
            <span title={rowData.email}>{rowData.email}</span>
            <button type="button" className="btn btn-link admin-offers-copy-btn" onClick={(event) => this.onCopyColumnClick(event, rowData.email)}>
                <span className="far fa-copy" />
            </button>
        </div>;
    }

    render() {
        let legendText = <div className="text-nowrap d-inline">
            <div className="d-inline admin-legend admin-offers-confirm" />&nbsp;&nbsp;&nbsp;Confirmed
            <div className="d-inline admin-legend admin-offers-warn" />&nbsp;&nbsp;&nbsp;Warn
            <div className="d-inline admin-legend admin-offers-error" />&nbsp;&nbsp;&nbsp;Error
            <div className="d-inline admin-legend admin-offers-expired" />&nbsp;&nbsp;&nbsp;Expired
            <div className="d-inline admin-legend admin-offers-source" />&nbsp;&nbsp;&nbsp;Internal
        </div>;

        return <SellToWebAdminPageComponent>
            <div className="admin-flex-item admin-body">
                <SellToWebAdminModalDialogComponent
                    title={this.state.rawViewTitle}
                    showDialog={this.state.showRawViewPopup}
                    className="admin-offers-raw-view-dialog"
                    onCancelClick={this.onCancelRawView}
                >
                    <SellToWebModalDialogHeaderButtonComponent>
                        <button className="btn btn-primary admin-copy-button" onClick={(event) => this.onCopyRawView(event)}>
                            Copy <span className="far fa-copy admin-icon" />
                        </button>
                    </SellToWebModalDialogHeaderButtonComponent>
                    {
                        this.state.rawViewData
                            ? <div className="admin-body">
                                <SellToWebAdminTextAreaComponent id="offerRawView" value={this.state.rawViewData} rows="25" controlSpan="col-12" readOnly />
                            </div>
                            : <SellToWebSpinnerComponent showSpinner={!this.state.rawViewData} />
                    }
                </SellToWebAdminModalDialogComponent>
                <div className="d-flex flex-column admin-fill-content">
                    <TenantOffersQueryComponent
                        tenants={this.state.tenants}
                        isLoadingData={this.state.isLoadingData}
                        onGetReportClick={this.onGetReportClick}
                        onShowAlert={this.props.onShowAlert}
                        isGlobalAdmin={this.props.isGlobalAdmin}
                        applicationInsights={this.props.applicationInsights}
                        logon={this.state.logon}
                    />
                    <br />
                    <SellToWebTableComponent cardClassName="admin-flex-item" headerText={this.state.tableTitle} tableData={this.state.tableData} paintTable={this.state.paintTable} legendText={legendText} tablePageSize={CommonService.tablePageSize}
                        copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10], onCopyTableCallBack: this.onCopyTableCallBack }}
                    >
                        <SellToWebTableRowComponent>
                            <SellToWebTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                            <SellToWebTableColumnComponent headerText="Source" dataColumn="source" sortColumn="source" filterColumn="source" />
                            <SellToWebTableColumnComponent headerText="Offer Number" dataColumnCallback={this.onOfferNumberColumnCallback} headerClassName="admin-offers-number-column" dataColumn="offerNumber" sortColumn="offerNumber" filterColumn="offerNumber" />
                            <SellToWebTableColumnComponent headerText="VIN" dataColumnCallback={this.onVinColumnCallback} dataColumn="vin" sortColumn="vin" filterColumn="vin" />
                            <SellToWebTableColumnComponent headerText="Offer Date" dataColumn="offerDate" sortColumn="offerDate" filterColumn="offerDate" dataType="dateTime" />
                            <SellToWebTableColumnComponent headerText="Effective Date" dataColumn="effectiveDate" sortColumn="effectiveDate" filterColumn="effectiveDate" dataType="date" />
                            <SellToWebTableColumnComponent headerText="Expiration Date" dataColumn="expirationDate" sortColumn="expirationDate" filterColumn="expirationDate" dataType="date" />
                            <SellToWebTableColumnComponent headerText="Vehicle" dataColumn="vehicle" sortColumn="vehicle" filterColumn="vehicle" />
                            <SellToWebTableColumnComponent headerText="Customer Name" dataColumn="customerName" sortColumn="customerName" filterColumn="customerName" />
                            <SellToWebTableColumnComponent headerText="Email" dataColumnCallback={this.onEmailColumnCallback} dataColumn="email" sortColumn="email" filterColumn="email" />
                            <SellToWebTableColumnComponent headerText="Offer" dataColumn="offerAmount" sortColumn="offerAmount" filterColumn="offerAmount" dataType="money_2" />
                            <SellToWebTableColumnComponent headerText="Status" dataColumn="status" sortColumn="status" filterColumn="status" />
                        </SellToWebTableRowComponent>
                        <SellToWebTableRowComponent>
                            <SellToWebTableColumnComponent colSpan="13" dataColumnCallback={this.onViewOfferDetailCallback} />
                        </SellToWebTableRowComponent>
                    </SellToWebTableComponent>
                </div>
            </div>
        </SellToWebAdminPageComponent>;
    }
}