import React, { Component, Fragment } from "react";
import moment from "moment";

import { CommonService } from "../js_modules/CommonService";

import { SellToWebAdminInputComponent } from "../common/selltowebinput/SellToWebAdminInputComponent";
import { SellToWebSpinnerComponent } from "../common/selltowebspinner/SellToWebSpinnerComponent";
import { SellToWebAdminCalendarRangeComponent } from "../common/selltowebcalendar/SellToWebAdminCalendarRangeComponent";
import { SellToWebAdminCheckboxComponent } from "../common/selltowebcheckbox/SellToWebAdminCheckboxComponent";
import { SellToWebAdminDropdownComponent } from "../common/selltowebdropdown/SellToWebAdminDropdownComponent";
import { AdminRouteComponent } from "../admin/AdminRouteComponent";

export class TenantOffersQueryComponent extends Component {
    static VinCookie = "OffersVinCookie";
    static EmailCookie = "OffersEmailCookie";
    static ShowExpiredCookie = "OffersShowExpiredCookie";

    valuationMethod = [
        { key: "KBB", value: "KBB" },
        { key: "MMR", value: "MMR" }
    ];

    dateRange = [
        { key: "MTD", value: "MTD", startDate: moment().startOf("month"), endDate: moment() },
        { key: "Last Month", value: "Last Month", startDate: moment().subtract(1, "months").startOf("month"), endDate: moment().subtract(1, "months").endOf("month") },
        { key: "2 Months Back", value: "2 Months Back", startDate: moment().subtract(2, "months").startOf("month"), endDate: moment().subtract(2, "months").endOf("month") },
        { key: "YTD", value: "YTD", startDate: moment().date(1).month(0).year(moment().year()), endDate: moment() },
        { key: "Last Year", value: "Last Year", startDate: moment().subtract(1, "years").startOf("year"), endDate: moment().subtract(1, "years").endOf("year") },
        { key: "2 Years Back", value: "2 Years Back", startDate: moment().subtract(2, "years").startOf("year"), endDate: moment().subtract(2, "years").endOf("year") },
        { key: "Custom", value: "Custom", startDate: null, endDate: null }
    ];

    constructor(props) {
        super(props);

        let startDate = moment().startOf("month");
        let endDate = moment()

        this.state = {
            selectedTenant: null,
            sites: null,
            selectedSite: null,
            startOfferDate: startDate,
            endOfferDate: endDate,
            selectedDateRange: this.dateRange[0],
            vin: "",
            email: "",
            showExpired: false,
            showSpinner: false,
            valuationMethod: null
        };

        this.onTenantClick = this.onTenantClick.bind(this);
        this.onSiteClick = this.onSiteClick.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.onShowExpiredChange = this.onShowExpiredChange.bind(this);
        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onValuationMethodChange = this.onValuationMethodChange.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (this.props.tenants && !this.state.selectedTenant) {
                let selectedTenant = null;
                let cookieTenant = CommonService.getCookie(AdminRouteComponent.TenantCookie);
                if (cookieTenant !== "") {
                    selectedTenant = this.props.tenants.find(tenant => tenant.guid === cookieTenant);
                }
                if (!selectedTenant) {
                    selectedTenant = this.props.tenants[0];
                }

                this.setState({
                    selectedTenant: selectedTenant
                }, this.onTenantClick(null, selectedTenant));
            }
        }
        catch (error) {
            console.logError(error, "TenantOffersQueryComponent", "componentDidUpdate");
        }
    }

    getSites() {
        this.setState({ showSpinner: true });

        let inputData = {
            tenantGuid: this.state.selectedTenant.guid,
        };
        this.props.applicationInsights.trackTrace({ message: "TenantOffersQueryComponent/getSites", properties: { ...inputData, logon: this.props.logon } });
        fetch("/TenantOffers/GetSites", {
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8;"
            },
            body: JSON.stringify(inputData),
            credentials: "same-origin"
        })
            .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
            .then(parsedResponse => {
                if (!this.state.selectedSite) {
                    let cookieSite = CommonService.getCookie(AdminRouteComponent.SiteCookie);
                    let vin = CommonService.getCookie(TenantOffersQueryComponent.VinCookie);
                    let email = CommonService.getCookie(TenantOffersQueryComponent.EmailCookie);
                    let showExpired = CommonService.getCookie(TenantOffersQueryComponent.ShowExpiredCookie) === "true";

                    let selectedSite = null;
                    if (cookieSite !== "") {
                        selectedSite = parsedResponse.find(site => site.guid === cookieSite);
                    }

                    if (!selectedSite) {
                        selectedSite = parsedResponse[0];
                    }

                    this.setState({
                        showSpinner: false,
                        sites: parsedResponse,
                        selectedSite: selectedSite,
                        vin: vin,
                        email: email,
                        showExpired: showExpired
                    }, this.onSiteClick(null, selectedSite));
                }
                else {
                    this.setState({
                        showSpinner: false,
                        sites: parsedResponse,
                        selectedSite: parsedResponse[0]
                    }, this.onSiteClick(null, this.state.selectedSite));
                }
            })
            .catch(notOKResponse => {
                this.setState({
                    showSpinner: false
                });
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/TenantOffers/GetSites", ...parsedError, logon: this.props.logon } });
                            console.logError(parsedError, "TenantOffersQueryComponent", "getSites");
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.logError(jsonParseError, "TenantOffersQueryComponent", "getSites");
                        });
                }
            });
    }

    onTenantClick(event, selectedTenant) {
        try {
            CommonService.setCookie(AdminRouteComponent.TenantCookie, selectedTenant ? selectedTenant.guid : "", 30);
            this.setState({
                selectedTenant: selectedTenant
            }, this.getSites);

        }
        catch (error) {
            console.logError(error, "TenantOffersQueryComponent", "onTenantClick");
        }
    }

    onSiteClick(event, selectedSite) {
        try {
            CommonService.setCookie(AdminRouteComponent.SiteCookie, selectedSite ? selectedSite.guid : "", 30);
            this.setState({
                selectedSite: selectedSite
            }, this.onValuationMethodChange(null, selectedSite.valuationMethod));

        }
        catch (error) {
            console.logError(error, "TenantOffersQueryComponent", "onSiteClick");
        }
    }

    onTextChange(id, value) {
        try {
            this.setState({ [id]: value });
        }
        catch (error) {
            console.logError(error, "TenantOffersQueryComponent", "onTextChange", id);
        }
    }

    onDateChange(selectedDateRange, startDate, endDate) {
        try {
            this.setState({
                selectedDateRange: selectedDateRange,
                startOfferDate: startDate,
                endOfferDate: endDate
            });
        }
        catch (error) {
            console.logError(error, "TenantOffersQueryComponent", "onDateChange");
        }
    }

    onKeyDown(event) {
        try {
            switch (event.keyCode) {
                case 13:
                    this.onGetReportClick(event);
                    break;
                default:
                    break;
            }
        }
        catch (error) {
            console.logError(error, "TenantOffersQueryComponent", "onKeyDown");
        }
    }

    onGetReportClick(event) {
        try {
            CommonService.setCookie(TenantOffersQueryComponent.VinCookie, this.state.vin, 30);
            CommonService.setCookie(TenantOffersQueryComponent.EmailCookie, this.state.email, 30);
            CommonService.setCookie(TenantOffersQueryComponent.ShowExpiredCookie, this.state.showExpired, 30);
            this.props.onGetReportClick(this.state.selectedTenant, this.state.selectedSite, this.state.startOfferDate, this.state.endOfferDate, this.state.vin ? this.state.vin : null, this.state.email ? this.state.email : null, this.state.valuationMethod, this.state.showExpired);
        }
        catch (error) {
            console.logError(error, "TenantOffersQueryComponent", "onGetReportClick");
        }
    }

    onShowExpiredChange(id, isChecked) {
        try {
            this.setState({ [id]: isChecked });
        }
        catch (error) {
            console.logError(error, "TenantOffersQueryComponent", "onShowExpiredChange");
        }
    }

    onValuationMethodChange(event, item) {
        try {
            this.setState({
                valuationMethod: item.value ?? item
            }, this.onGetReportClick);
        }
        catch (error) {
            console.logError(error, "TenantOffersQueryComponent", "onShowExpiredChange");
        }
    }

    render() {
        let minDate = moment().subtract(2, "years").startOf("year").format("MM/DD/YYYY"),
            maxDate = moment().format("MM/DD/YYYY");

        return <div className="card">
            <div className="card-block">
                <div className="row">
                    {
                        this.props.isGlobalAdmin
                            ? <SellToWebAdminDropdownComponent label="Tenant" data={this.props.tenants} itemKey="name" onItemClick={this.onTenantClick} value={this.state.selectedTenant ? this.state.selectedTenant.name : "Select Tenant"} controlSpan="admin-tenant-control" />
                            : <Fragment>
                                <div className={"admin-control col-auto"}>
                                    <div className="input-group align-items-center">
                                        <label className={"admin-label col-form-label"}>
                                            Tenant:
                                        </label>
                                        {this.state.selectedTenant?.name}
                                    </div>
                                </div>
                            </Fragment>
                    }
                    <SellToWebAdminDropdownComponent label="Site" data={this.state.sites} itemKey="siteName" onItemClick={this.onSiteClick} value={this.state.selectedSite ? this.state.selectedSite.siteName : "Select Site"} />
                    <SellToWebAdminCalendarRangeComponent
                        label="Offer Date Range" dateRange={this.dateRange} selectedDateRange={this.state.selectedDateRange} onDateChange={this.onDateChange}
                        startId="startOfferDate" startValue={this.state.startOfferDate} startMin={minDate} startMax={maxDate}
                        endId="endOfferDate" endValue={this.state.endOfferDate} endMin={minDate} endMax={maxDate} />
                    <SellToWebAdminInputComponent id="vin" label="VIN" value={this.state.vin} onChange={this.onTextChange} onKeyDown={this.onKeyDown} />
                    <SellToWebAdminInputComponent id="email" label="Email" value={this.state.email} onChange={this.onTextChange} onKeyDown={this.onKeyDown} />
                    <SellToWebAdminDropdownComponent id="valuationMethod" label="Valuation Method" data={this.valuationMethod} itemKey="value" onItemClick={this.onValuationMethodChange} value={this.state.valuationMethod ? this.state.valuationMethod : "Select Valuation Method"} />
                    <SellToWebAdminCheckboxComponent id="showExpired" label="Show Expired" value={this.state.showExpired} controlClassName="align-middle" onChange={this.onShowExpiredChange} />
                    <div className="col-auto admin-query-btn">
                        <button className="btn btn-primary" disabled={this.state.showSpinner} onClick={(event) => this.onGetReportClick(event)}>
                            Get Report <i className="far fa-redo-alt admin-icon" />
                        </button>
                    </div>
                    <div style={{ "height": "33px", "width": "auto" }}>
                        <SellToWebSpinnerComponent showSpinner={this.state.showSpinner || this.props.isLoadingData} />
                    </div>
                </div>
            </div>
        </div>;
    }
}
