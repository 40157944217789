import React, { Component } from "react";

import { CommonService } from "../js_modules/CommonService";

import { SellToWebAdminDropdownComponent } from "../common/selltowebdropdown/SellToWebAdminDropdownComponent";
import { SellToWebAdminInputComponent } from "../common/selltowebinput/SellToWebAdminInputComponent";
import { SellToWebModalDialogHeaderButtonComponent } from "../common/selltowebmodaldialog/SellToWebModalDialogHeaderButtonComponent";
import { SellToWebSpinnerComponent } from "../common/selltowebspinner/SellToWebSpinnerComponent";
import { SellToWebAdminToggleComponent } from "../common/selltowebcheckbox/SellToWebAdminToggleComponent";
import { SellToWebAdminModalDialogComponent } from "../common/selltowebmodaldialog/SellToWebAdminModalDialogComponent";

export class TenantSiteOfferMMRCalculationEditComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            maximumOfferAmountEnabled: false,
            maximumOdometerEnabled: false,
            minimumYearEnabled: false,
            minimumAverageGradeEnabled: false,
            gradeLikeNewConditionValueEnabled: false,
            gradeVeryGoodConditionValueEnabled: false,
            gradeAcceptableConditionValueEnabled: false,
            gradeMajorIssuesConditionValueEnabled: false,
            tireAdjustmentBelowThresholdAmountEnabled: false,
            tireAdjustmentAboveThresholdAmountEnabled: false,
            tireAdjustmentThresholdEnabled: false,
            windshieldAdjustmentBelowThresholdAmountEnabled: false,
            windshieldAdjustmentAboveThresholdAmountEnabled: false,
            windshieldAdjustmentThresholdEnabled: false,
            accidentAdjustmentValueEnabled: false,
            generalAdjustmentValueEnabled: false,
            enableOfferCalculation: false,
            maximumOfferAmount: "",
            maximumOdometer: "",
            minimumYear: "",
            minimumAverageGrade: "",
            gradeLikeNewConditionValue: "",
            gradeVeryGoodConditionValue: "",
            gradeAcceptableConditionValue: "",
            gradeMajorIssuesConditionValue: "",
            tireAdjustmentBelowThresholdAmount: "",
            tireAdjustmentAboveThresholdAmount: "",
            tireAdjustmentThreshold: "",
            windshieldAdjustmentBelowThresholdAmount: "",
            windshieldAdjustmentAboveThresholdAmount: "",
            windshieldAdjustmentThreshold: "",
            accidentAdjustmentIsDollar: true,
            accidentAdjustmentValue: "",
            generalAdjustmentIsDollar: true,
            generalAdjustmentValue: "",
            isLoadingData: false,
            saveDisabled: true,
            enableYMM: false,
            vehicleMakes: null,
            makes: null,
            selectedMake: null,
            years: null,
            selectedYear: null,
            models: null,
            selectedModel: null
        };

        this.onTextChange = this.onTextChange.bind(this);
        this.onCheckboxChange = this.onCheckboxChange.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onSaveAsClick = this.onSaveAsClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onDisableSave = this.onDisableSave.bind(this);
        this.onYearClick = this.onYearClick.bind(this);
        this.onMakeClick = this.onMakeClick.bind(this);
        this.onModelClick = this.onModelClick.bind(this);
    }

    makesMultiple = [{ makeName: "Multiple" }];
    yearsMultiple = ["Multiple"];
    modelsMultiple = [{ modelName: "Multiple" }];
    makesDefault = [{ makeName: "Default" }];
    yearsDefault = ["Default"];
    modelsDefault = [{ modelName: "Default" }];

    componentDidMount() {
        try {
            this.getVehicleMakes();
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferMMRCalculationEditComponent", "componentDidMount");
        }
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (!prevProps.showDialog && this.props.showDialog) {
                if (this.props.rowData?.length > 0) {
                    if (this.props.rowData.length > 1) {
                        this.setState({
                            years: this.yearsMultiple,
                            selectedYear: this.yearsMultiple[0],
                            makes: this.makesMultiple,
                            selectedMake: this.makesMultiple[0],
                            models: this.modelsMultiple,
                            selectedModel: this.modelsMultiple[0],
                            enableYMM: false
                        }, () => {
                            this.fillForm(this.props.rowData);
                        });
                    }
                    else if (this.props.rowData[0].isDefault) {
                        this.setState({
                            years: this.yearsDefault,
                            selectedYear: this.yearsDefault[0],
                            makes: this.makesDefault,
                            selectedMake: this.makesDefault[0],
                            models: this.modelsDefault,
                            selectedModel: this.modelsDefault[0],
                            enableYMM: false
                        }, () => {
                            this.fillForm(this.props.rowData[0]);
                        });
                    }
                    else {
                        let years = CommonService.getPreviousYearsByDate(25);
                        let makes = this.state.vehicleMakes;
                        let selectedYear = null, selectedMake = null;
                        let rowData = this.props.rowData[0];
                        if (rowData.year) {
                            selectedYear = CommonService.formatYear(rowData.year.toString());
                        }
                        if (rowData.make) {
                            selectedMake = makes.filter(make => make.makeName === rowData.make)[0];
                        }

                        this.setState({
                            years: years,
                            selectedYear: selectedYear,
                            makes: makes,
                            selectedMake: selectedMake,
                            models: null,
                            selectedModel: null,
                            enableYMM: false
                        }, () => {
                            this.fillForm(this.props.rowData[0]);
                            if (rowData.make) {
                                this.getVehicleModels();
                            }
                        });
                    }
                }
                else {
                    this.setState({
                        years: CommonService.getPreviousYearsByDate(25),
                        selectedYear: null,
                        makes: this.state.vehicleMakes,
                        selectedMake: null,
                        models: null,
                        selectedModel: null,
                        enableYMM: true
                    });
                }
            }
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferMMRCalculationEditComponent", "componentDidUpdate");
        }
    }

    clearForm() {
        this.setState({
            selectedMake: null,
            selectedYear: null,
            models: null,
            selectedModel: null,
            enableOfferCalculation: true,
            maximumOfferAmount: "",
            maximumOdometer: "",
            minimumYear: "",
            minimumAverageGrade: "",
            gradeLikeNewConditionValue: "",
            gradeVeryGoodConditionValue: "",
            gradeAcceptableConditionValue: "",
            gradeMajorIssuesConditionValue: "",
            tireAdjustmentBelowThresholdAmount: "",
            tireAdjustmentAboveThresholdAmount: "",
            tireAdjustmentThreshold: "",
            windshieldAdjustmentBelowThresholdAmount: "",
            windshieldAdjustmentAboveThresholdAmount: "",
            windshieldAdjustmentThreshold: "",
            accidentAdjustmentIsDollar: true,
            accidentAdjustmentValue: "",
            generalAdjustmentIsDollar: true,
            generalAdjustmentValue: "",
            saveDisabled: true,
            enableYMM: false,
            isLoadingData: false,
            maximumOfferAmountEnabled: false,
            maximumOdometerEnabled: false,
            minimumYearEnabled: false,
            minimumAverageGradeEnabled: false,
            gradeLikeNewConditionValueEnabled: false,
            gradeVeryGoodConditionValueEnabled: false,
            gradeAcceptableConditionValueEnabled: false,
            gradeMajorIssuesConditionValueEnabled: false,
            tireAdjustmentBelowThresholdAmountEnabled: false,
            tireAdjustmentAboveThresholdAmountEnabled: false,
            tireAdjustmentThresholdEnabled: false,
            windshieldAdjustmentBelowThresholdAmountEnabled: false,
            windshieldAdjustmentAboveThresholdAmountEnabled: false,
            windshieldAdjustmentThresholdEnabled: false,
            generalAdjustmentIsDollar: true,
            accidentAdjustmentIsDollar: true
        }, this.onDisableSave);
    }

    fillForm(rowData) {
        this.setState({
            maximumOfferAmount: this.props.rowData.length > 1 ? "" : CommonService.formatNumber_2(rowData.maximumOfferAmount),
            maximumOdometer: this.props.rowData.length > 1 ? "" : CommonService.formatNumber(rowData.maximumOdometer),
            minimumYear: this.props.rowData.length > 1 ? "" : rowData.minimumYear ?? 0,
            minimumAverageGrade: this.props.rowData.length > 1 ? "" : CommonService.formatNumber(rowData.minimumAverageGrade),
            gradeLikeNewConditionValue: this.props.rowData.length > 1 ? "" : CommonService.formatNumber(rowData.gradeLikeNewConditionValue),
            gradeVeryGoodConditionValue: this.props.rowData.length > 1 ? "" : CommonService.formatNumber(rowData.gradeVeryGoodConditionValue),
            gradeAcceptableConditionValue: this.props.rowData.length > 1 ? "" : CommonService.formatNumber(rowData.gradeAcceptableConditionValue),
            gradeMajorIssuesConditionValue: this.props.rowData.length > 1 ? "" : CommonService.formatNumber(rowData.gradeMajorIssuesConditionValue),
            tireAdjustmentBelowThresholdAmount: this.props.rowData.length > 1 ? "" : CommonService.formatNumber_2(rowData.tireAdjustmentBelowThresholdAmount),
            tireAdjustmentAboveThresholdAmount: this.props.rowData.length > 1 ? "" : CommonService.formatNumber_2(rowData.tireAdjustmentAboveThresholdAmount),
            tireAdjustmentThreshold: this.props.rowData.length > 1 ? "" : CommonService.formatNumber_2(rowData.tireAdjustmentThreshold),
            windshieldAdjustmentBelowThresholdAmount: this.props.rowData.length > 1 ? "" : CommonService.formatNumber_2(rowData.windshieldAdjustmentBelowThresholdAmount),
            windshieldAdjustmentAboveThresholdAmount: this.props.rowData.length > 1 ? "" : CommonService.formatNumber_2(rowData.windshieldAdjustmentAboveThresholdAmount),
            windshieldAdjustmentThreshold: this.props.rowData.length > 1 ? "" : CommonService.formatNumber_2(rowData.windshieldAdjustmentThreshold),
            accidentAdjustmentIsDollar: this.props.rowData.length > 1 ? true : rowData.accidentAdjustmentIsDollar,
            accidentAdjustmentValue: this.props.rowData.length > 1 ? "" : (rowData.accidentAdjustmentIsDollar ? CommonService.formatNumber_2(rowData.accidentAdjustmentValue) : CommonService.formatNumber_1(rowData.accidentAdjustmentValue * 100)),
            generalAdjustmentIsDollar: this.props.rowData.length > 1 ? true : rowData.generalAdjustmentIsDollar,
            generalAdjustmentValue: this.props.rowData.length > 1 ? "" : (rowData.generalAdjustmentIsDollar ? CommonService.formatNumber_2(rowData.generalAdjustmentValue) : CommonService.formatNumber_1(rowData.generalAdjustmentValue * 100)),
            enableOfferCalculation: rowData.enableOfferCalculation,
            maximumOfferAmountEnabled: rowData.maximumOfferAmountIsDefault,
            maximumOdometerEnabled: rowData.maximumOdometerIsDefault,
            minimumYearEnabled: rowData.minimumYearIsDefault,
            minimumAverageGradeEnabled: rowData.minimumAverageGradeIsDefault,
            gradeLikeNewConditionValueEnabled: rowData.gradeLikeNewConditionValueIsDefault,
            gradeVeryGoodConditionValueEnabled: rowData.gradeVeryGoodConditionValueIsDefault,
            gradeAcceptableConditionValueEnabled: rowData.gradeAcceptableConditionValueIsDefault,
            gradeMajorIssuesConditionValueEnabled: rowData.gradeMajorIssuesConditionValueIsDefault,
            tireAdjustmentBelowThresholdAmountEnabled: rowData.tireAdjustmentBelowThresholdAmountIsDefault,
            tireAdjustmentAboveThresholdAmountEnabled: rowData.tireAdjustmentAboveThresholdAmountIsDefault,
            tireAdjustmentThresholdEnabled: rowData.tireAdjustmentThresholdIsDefault,
            windshieldAdjustmentBelowThresholdAmountEnabled: rowData.windshieldAdjustmentBelowThresholdAmountIsDefault,
            windshieldAdjustmentAboveThresholdAmountEnabled: rowData.windshieldAdjustmentAboveThresholdAmountIsDefault,
            windshieldAdjustmentThresholdEnabled: rowData.windshieldAdjustmentThresholdIsDefault,
            accidentAdjustmentValueEnabled: rowData.accidentAdjustmentValueIsDefault,
            generalAdjustmentValueEnabled: rowData.generalAdjustmentValueIsDefault,
            isLoadingData: false
        }, this.onDisableSave);
    }

    getVehicleMakes() {
        try {
            this.setState({ isLoadingData: true });
            let inputData = {
                year: this.state.selectedYear ? CommonService.formatYear(this.state.selectedYear) : ""
            };
            this.props.applicationInsights.trackTrace({ message: "TenantSiteOfferMMRCalculationEditComponent/getVehicleMakes", properties: { ...inputData, logon: this.props.logon } });
            fetch("/TenantSite/GetVehicleMakes", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData),
                credentials: "same-origin"
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        vehicleMakes: parsedResponse.items,
                        isLoadingData: false
                    });
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/TenantSite/GetVehicleMakes", ...inputData, ...parsedError, logon: this.props.logon } });
                                console.logError(parsedError, "TenantSiteOfferMMRCalculationEditComponent", "getVehicleMakes");
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.logError(jsonParseError, "TenantSiteOfferMMRCalculationEditComponent", "getVehicleMakes");
                            });
                    }
                });
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferMMRCalculationEditComponent", "getVehicleMakes");
        }
    }

    getVehicleModels() {
        try {
            this.setState({ isLoadingData: true });
            let inputData = {
                year: this.state.selectedYear ? CommonService.formatYear(this.state.selectedYear) : "",
                makeId: this.state.selectedMake.makeId,
            };

            this.props.applicationInsights.trackTrace({ message: "TenantSiteOfferMMRCalculationEditComponent/getVehicleModels", properties: { ...inputData, logon: this.props.logon } });
            fetch("/TenantSite/GetVehicleModels", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData),
                credentials: "same-origin"
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let selectedModel = null;
                    if (this.props.rowData?.length === 1) {
                        let rowData = this.props.rowData[0];
                        if (rowData.model) {
                            selectedModel = parsedResponse.items.filter(model => model.modelName.toString() === rowData.model.toString())[0];
                        }
                    }
                    this.setState({
                        models: parsedResponse.items,
                        selectedModel: selectedModel,
                        isLoadingData: false
                    });
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/TenantSite/GetVehicleModels", ...inputData, ...parsedError, logon: this.props.logon } });
                                console.logError(parsedError, "TenantSiteOfferMMRCalculationEditComponent", "getVehicleModels");
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.logError(jsonParseError, "TenantSiteOfferMMRCalculationEditComponent", "getVehicleModels");
                            });
                    }
                });
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferMMRCalculationEditComponent", "getVehicleModels");
        }
    }

    onTextChange(id, value) {
        try {
            this.setState({ [id]: value }, this.onDisableSave);
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferMMRCalculationEditComponent", "onTextChange", id);
        }
    }

    onCheckboxChange(id, value) {
        try {
            this.setState({ [id]: value }, this.onDisableSave);
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferMMRCalculationEditComponent", "onCheckboxChange", id);
        }
    }

    checkIfDefault() {
        return (this.state.selectedYear === "Default" && this.state.selectedMake?.makeName === "Default" && this.state.selectedModel?.modelName === "Default");
    }

    getData() {
        let changedFields = {};

        if (this.state.enableOfferCalculation) {
            changedFields.enableOfferCalculation = this.state.enableOfferCalculation;
        }

        if (this.state.maximumOfferAmountEnabled || this.state.maximumOfferAmount !== "") {
            changedFields.maximumOfferAmount = !this.state.maximumOfferAmountEnabled ? parseFloat(this.state.maximumOfferAmount.replace(/,/g, '')) : null;
        }

        if (this.state.maximumOdometerEnabled || this.state.maximumOdometer !== "") {
            changedFields.maximumOdometer = !this.state.maximumOdometerEnabled ? parseFloat(this.state.maximumOdometer.replace(/,/g, '')) : null;
        }

        if (this.state.minimumYearEnabled || this.state.minimumYear !== "") {
            changedFields.minimumYear = !this.state.minimumYearEnabled ? parseInt(this.state.minimumYear) : null;
        }

        if (this.state.minimumAverageGradeEnabled || this.state.minimumAverageGrade !== "") {
            changedFields.minimumAverageGrade = !this.state.minimumAverageGradeEnabled ? parseFloat(this.state.minimumAverageGrade.replace(/,/g, ''), 10) : null;
        }

        if (this.state.gradeLikeNewConditionValueEnabled || this.state.gradeLikeNewConditionValue !== "") {
            changedFields.gradeLikeNewConditionValue = !this.state.gradeLikeNewConditionValueEnabled ? parseFloat(this.state.gradeLikeNewConditionValue.replace(/,/g, ''), 10) : null;
        }

        if (this.state.gradeVeryGoodConditionValueEnabled || this.state.gradeVeryGoodConditionValue !== "") {
            changedFields.gradeVeryGoodConditionValue = !this.state.gradeVeryGoodConditionValueEnabled ? parseFloat(this.state.gradeVeryGoodConditionValue.replace(/,/g, ''), 10) : null;
        }

        if (this.state.gradeAcceptableConditionValueEnabled || this.state.gradeAcceptableConditionValue !== "") {
            changedFields.gradeAcceptableConditionValue = !this.state.gradeAcceptableConditionValueEnabled ? parseFloat(this.state.gradeAcceptableConditionValue.replace(/,/g, ''), 10) : null;
        }

        if (this.state.gradeMajorIssuesConditionValueEnabled || this.state.gradeMajorIssuesConditionValue !== "") {
            changedFields.gradeMajorIssuesConditionValue = !this.state.gradeMajorIssuesConditionValueEnabled ? parseFloat(this.state.gradeMajorIssuesConditionValue.replace(/,/g, ''), 10) : null;
        }

        if (this.state.tireAdjustmentBelowThresholdAmountEnabled || this.state.tireAdjustmentBelowThresholdAmount !== "") {
            changedFields.tireAdjustmentBelowThresholdAmount = !this.state.tireAdjustmentBelowThresholdAmountEnabled ? parseFloat(this.state.tireAdjustmentBelowThresholdAmount.replace(/,/g, ''), 10) : null;
        }

        if (this.state.tireAdjustmentAboveThresholdAmountEnabled || this.state.tireAdjustmentAboveThresholdAmount !== "") {
            changedFields.tireAdjustmentAboveThresholdAmount = !this.state.tireAdjustmentAboveThresholdAmountEnabled ? parseFloat(this.state.tireAdjustmentAboveThresholdAmount.replace(/,/g, ''), 10) : null;
        }

        if (this.state.tireAdjustmentThresholdEnabled || this.state.tireAdjustmentThreshold !== "") {
            changedFields.tireAdjustmentThreshold = !this.state.tireAdjustmentThresholdEnabled ? parseFloat(this.state.tireAdjustmentThreshold.replace(/,/g, ''), 10) : null;
        }

        if (this.state.windshieldAdjustmentBelowThresholdAmountEnabled || this.state.windshieldAdjustmentBelowThresholdAmount !== "") {
            changedFields.windshieldAdjustmentBelowThresholdAmount = !this.state.windshieldAdjustmentBelowThresholdAmountEnabled ? parseFloat(this.state.windshieldAdjustmentBelowThresholdAmount.replace(/,/g, ''), 10) : null;
        }

        if (this.state.windshieldAdjustmentAboveThresholdAmountEnabled || this.state.windshieldAdjustmentAboveThresholdAmount !== "") {
            changedFields.windshieldAdjustmentAboveThresholdAmount = !this.state.windshieldAdjustmentAboveThresholdAmountEnabled ? parseFloat(this.state.windshieldAdjustmentAboveThresholdAmount.replace(/,/g, ''), 10) : null;
        }

        if (this.state.windshieldAdjustmentThresholdEnabled || this.state.windshieldAdjustmentThreshold !== "") {
            changedFields.windshieldAdjustmentThreshold = !this.state.windshieldAdjustmentThresholdEnabled ? parseFloat(this.state.windshieldAdjustmentThreshold.replace(/,/g, ''), 10) : null;
        }

        if (this.state.accidentAdjustmentValueEnabled || this.state.accidentAdjustmentValue !== "") {
            changedFields.accidentAdjustmentValue = !this.state.accidentAdjustmentValueEnabled ? (this.state.accidentAdjustmentIsDollar ? parseFloat(this.state.accidentAdjustmentValue.replace(/,/g, '')) : (parseFloat(this.state.accidentAdjustmentValue.replace(/,/g, '')) / 100)) : null;
            changedFields.accidentAdjustmentIsDollar = !this.state.accidentAdjustmentValueEnabled ? this.state.accidentAdjustmentIsDollar : null;
        }

        if (this.state.generalAdjustmentValueEnabled || this.state.generalAdjustmentValue !== "") {
            changedFields.generalAdjustmentValue = !this.state.generalAdjustmentValueEnabled ? (this.state.generalAdjustmentIsDollar ? parseFloat(this.state.generalAdjustmentValue.replace(/,/g, '')) : (parseFloat(this.state.generalAdjustmentValue.replace(/,/g, '')) / 100)) : null;
            changedFields.generalAdjustmentIsDollar = !this.state.generalAdjustmentValueEnabled ? this.state.generalAdjustmentIsDollar : null;
        }

        return changedFields;
    };

    onSaveClick() {
        try {
            this.setState({
                isLoadingData: true,
                saveDisabled: true
            });
            let inputData = {
                siteGuid: this.props.selectedSite.guid,
                isDefault: this.checkIfDefault(),
                year: (this.state.selectedYear && this.state.selectedYear !== "Default" && this.state.selectedYear !== "Multiple" && this.state.selectedYear !== "All") ? parseInt(CommonService.formatYear(this.state.selectedYear)) : null,
                make: (this.state.selectedMake && this.state.selectedMake.makeName !== "Default" && this.state.selectedMake.makeName !== "Multiple") ? this.state.selectedMake.makeName : null,
                model: (this.state.selectedModel && this.state.selectedModel.modelName !== "Default" && this.state.selectedModel.modelName !== "Multiple") ? this.state.selectedModel.modelName : null,
                trim: null,
                enableOfferCalculation: this.state.enableOfferCalculation,
                maximumOfferAmount: !this.state.maximumOfferAmountEnabled ? parseFloat(this.state.maximumOfferAmount.replace(/,/g, '')) : null,
                maximumOdometer: !this.state.maximumOdometerEnabled ? parseInt(this.state.maximumOdometer.replace(/,/g, '')) : null,
                minimumYear: !this.state.minimumYearEnabled ? parseInt(this.state.minimumYear) : null,
                minimumAverageGrade: !this.state.minimumAverageGradeEnabled ? parseFloat(this.state.minimumAverageGrade.replace(/,/g, '')) : null,
                gradeLikeNewConditionValue: !this.state.gradeLikeNewConditionValueEnabled ? parseFloat(this.state.gradeLikeNewConditionValue.replace(/,/g, '')) : null,
                gradeVeryGoodConditionValue: !this.state.gradeVeryGoodConditionValueEnabled ? parseFloat(this.state.gradeVeryGoodConditionValue.replace(/,/g, '')) : null,
                gradeAcceptableConditionValue: !this.state.gradeAcceptableConditionValueEnabled ? parseFloat(this.state.gradeAcceptableConditionValue.replace(/,/g, '')) : null,
                gradeMajorIssuesConditionValue: !this.state.gradeMajorIssuesConditionValueEnabled ? parseFloat(this.state.gradeMajorIssuesConditionValue.replace(/,/g, '')) : null,
                tireAdjustmentBelowThresholdAmount: !this.state.tireAdjustmentBelowThresholdAmountEnabled ? parseFloat(this.state.tireAdjustmentBelowThresholdAmount.replace(/,/g, '')) : null,
                tireAdjustmentAboveThresholdAmount: !this.state.tireAdjustmentAboveThresholdAmountEnabled ? parseFloat(this.state.tireAdjustmentAboveThresholdAmount.replace(/,/g, '')) : null,
                tireAdjustmentThreshold: !this.state.tireAdjustmentThresholdEnabled ? parseFloat(this.state.tireAdjustmentThreshold.replace(/,/g, '')) : null,
                windshieldAdjustmentBelowThresholdAmount: !this.state.windshieldAdjustmentBelowThresholdAmountEnabled ? parseFloat(this.state.windshieldAdjustmentBelowThresholdAmount.replace(/,/g, '')) : null,
                windshieldAdjustmentAboveThresholdAmount: !this.state.windshieldAdjustmentAboveThresholdAmountEnabled ? parseFloat(this.state.windshieldAdjustmentAboveThresholdAmount.replace(/,/g, '')) : null,
                windshieldAdjustmentThreshold: !this.state.windshieldAdjustmentThresholdEnabled ? parseFloat(this.state.windshieldAdjustmentThreshold.replace(/,/g, '')) : null,
                accidentAdjustmentIsDollar: !this.state.accidentAdjustmentValueEnabled ? this.state.accidentAdjustmentIsDollar : null,
                accidentAdjustmentValue: !this.state.accidentAdjustmentValueEnabled ? (this.state.accidentAdjustmentIsDollar ? parseFloat(this.state.accidentAdjustmentValue.replace(/,/g, '')) : (parseFloat(this.state.accidentAdjustmentValue.replace(/,/g, '')) / 100)) : null,
                generalAdjustmentIsDollar: !this.state.generalAdjustmentValueEnabled ? this.state.generalAdjustmentIsDollar : null,
                generalAdjustmentValue: !this.state.generalAdjustmentValueEnabled ? (this.state.generalAdjustmentIsDollar ? parseFloat(this.state.generalAdjustmentValue.replace(/,/g, '')) : (parseFloat(this.state.generalAdjustmentValue.replace(/,/g, '')) / 100)) : null,
                isDelete: false,
                siteName: this.props.selectedSite.siteName,
                tenantShortName: this.props.selectedTenant.shortName
            };
            let saveApiUrl = "/TenantSite/SaveSiteCalculationConfigMMR";
            if (this.props.rowData?.length > 1) {
                inputData = {
                    id: this.props.rowData.map(row => row.id),
                    data: this.getData(),
                    siteName: this.props.selectedSite.siteName,
                    tenantShortName: this.props.selectedTenant.shortName
                }
                saveApiUrl = "/TenantSite/SaveSiteCalculationConfigMMRBulk";
            }
            this.props.applicationInsights.trackTrace({ message: "TenantSiteOfferMMRCalculationEditComponent/onSaveClick", properties: { ...inputData, logon: this.props.logon } });
            fetch(saveApiUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData),
                credentials: "same-origin"
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        isLoadingData: false
                    }, () => {
                        this.clearForm();
                        this.props.onSaveClick();
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/TenantSite/SaveSiteCalculationVariable", ...inputData, ...parsedError, logon: this.props.logon } });
                                console.logError(parsedError, "TenantSiteOfferMMRCalculationEditComponent", "onSaveClick");
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.logError(jsonParseError, "TenantSiteOfferMMRCalculationEditComponent", "onSaveClick");
                            });
                    }
                });
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferMMRCalculationEditComponent", "onSaveClick");
        }
    }

    onSaveAsClick() {
        try {
            this.setState({
                enableYMM: true,
                makes: null,
                years: null,
                models: null,
                selectedMake: null,
                selectedYear: null,
                selectedModel: null
            });
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferMMRCalculationEditComponent", "onSaveAsClick");
        }
    }

    onCancelClick() {
        try {
            this.clearForm();
            this.onSaveAsClick();
            this.props.onCancelClick();
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferMMRCalculationEditComponent", "onCancelClick");
        }
    }

    onDisableSave() {
        try {
            let disabled = !(this.props.rowData && this.props.rowData.length > 1)
                && ((this.state.maximumOfferAmount === null || this.state.maximumOfferAmount === "")
                    || (this.state.maximumOdometer === null || this.state.maximumOdometer === "")
                    || (this.state.minimumYear === null || this.state.minimumYear === "")
                    || (this.state.minimumAverageGrade === null || this.state.minimumAverageGrade === "")
                    || (this.state.gradeLikeNewConditionValue === null || this.state.gradeLikeNewConditionValue === "")
                    || (this.state.gradeVeryGoodConditionValue === null || this.state.gradeVeryGoodConditionValue === "")
                    || (this.state.gradeAcceptableConditionValue === null || this.state.gradeAcceptableConditionValue === "")
                    || (this.state.gradeMajorIssuesConditionValue === null || this.state.gradeMajorIssuesConditionValue === "")
                    || (this.state.tireAdjustmentBelowThresholdAmount === null || this.state.tireAdjustmentBelowThresholdAmount === "")
                    || (this.state.tireAdjustmentAboveThresholdAmount === null || this.state.tireAdjustmentAboveThresholdAmount === "")
                    || (this.state.tireAdjustmentThreshold === null || this.state.tireAdjustmentThreshold === "")
                    || (this.state.windshieldAdjustmentBelowThresholdAmount === null || this.state.windshieldAdjustmentBelowThresholdAmount === "")
                    || (this.state.windshieldAdjustmentAboveThresholdAmount === null || this.state.windshieldAdjustmentAboveThresholdAmount === "")
                    || (this.state.windshieldAdjustmentThreshold === null || this.state.windshieldAdjustmentThreshold === "")
                    || (this.state.generalAdjustmentValue === null || this.state.generalAdjustmentValue === "")
                    || (this.state.accidentAdjustmentValue === null || this.state.accidentAdjustmentValue === ""));

            if (!disabled) {
                disabled = !(
                    (this.state.selectedYear && this.state.selectedMake && this.state.selectedModel)
                    || (this.state.selectedYear && this.state.selectedMake && !this.state.selectedModel)
                    || (!this.state.selectedYear && this.state.selectedMake && this.state.selectedModel)
                    || (!this.state.selectedYear && this.state.selectedMake && !this.state.selectedModel)
                    || (this.state.selectedYear && !this.state.selectedMake && !this.state.selectedModel)
                );
            }

            this.setState({ saveDisabled: disabled });
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferMMRCalculationEditComponent", "onDisableSave");
        }
    }

    onYearClick(id, year) {
        try {
            this.setState({
                selectedYear: year,
                models: null,
                selectedModel: null
            }, () => {
                this.onDisableSave();
                if (this.state.selectedMake !== null) {
                    this.getVehicleModels();
                }
            });
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferMMRCalculationEditComponent", "onYearClick", id);
        }
    }

    onMakeClick(id, make) {
        try {
            this.setState({
                selectedMake: make,
                models: null,
                selectedModel: null
            }, () => {
                this.onDisableSave();
                this.getVehicleModels();
            });

        }
        catch (error) {
            console.logError(error, "TenantSiteOfferMMRCalculationEditComponent", "onMakeClick", id);
        }
    }

    onModelClick(id, model) {
        try {
            this.setState({
                selectedModel: model
            }, () => {
                this.onDisableSave();
            });
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferMMRCalculationEditComponent", "onModelClick", id);
        }
    }

    render() {
        let legendText = <div className="text-nowrap d-inline">
            <div className="d-inline admin-legend admin-site-configuration-dialog-modal-legend-yellow" />&nbsp;&nbsp;&nbsp;Changed
        </div>;
        let modalTitle = "MMR Site Configuration";
        if (this.props.rowData && this.props.rowData?.length > 0) {
            let extendedTitle = (this.state.selectedYear ?? "")
                + (this.state.selectedMake && this.state.selectedMake.makeName ? " " + this.state.selectedMake.makeName : "")
                + (this.state.selectedModel && this.state.selectedModel.modelName ? " " + this.state.selectedModel.modelName : "");

            if (extendedTitle !== "")
                modalTitle += " - " + extendedTitle;
        }

        return <SellToWebAdminModalDialogComponent
            showDialog={this.props.showDialog}
            className="admin-site-configuration-dialog"
            title={modalTitle}
            onCancelClick={this.onCancelClick}
            legendText={(this.props.rowData?.length > 1) ? legendText : null}
        >
            <SellToWebModalDialogHeaderButtonComponent>
                {
                    this.props.rowData?.length === 1
                        ? <button className="btn btn-primary admin-icon " onClick={this.onSaveAsClick} disabled={this.state.enableYMM}>
                            Save As <i className="fas fa-save admin-icon" />
                        </button>
                        : null
                }
                <button className="btn btn-primary admin-icon " onClick={this.onSaveClick} disabled={this.state.saveDisabled}>
                    Save <i className="fas fa-save admin-icon" />
                </button>
            </SellToWebModalDialogHeaderButtonComponent>
            <div className="admin-body">
                <div className="card">
                    <div className="card-block">
                        <div className="row">
                            <SellToWebAdminDropdownComponent label="Make" data={this.state.makes} disabled={!this.state.enableYMM} itemKey="makeName" onItemClick={this.onMakeClick} value={this.state.selectedMake ? this.state.selectedMake.makeName : ""} colSpan="col-4" />
                            <SellToWebAdminDropdownComponent label="Year" data={this.state.years} disabled={!this.state.enableYMM} displayFormat="Year" onItemClick={this.onYearClick} value={this.state.selectedYear} colSpan="col-4" />
                            <SellToWebAdminDropdownComponent label="Model" data={this.state.models} disabled={!this.state.enableYMM} itemKey="modelName" onItemClick={this.onModelClick} value={this.state.selectedModel ? this.state.selectedModel.modelName : ""} colSpan="col-4" inputCssName="admin-site-configuration-dialog-modal-input" />
                            <SellToWebSpinnerComponent showSpinner={this.state.isLoadingData} />
                        </div>
                        <div className="row">
                            <SellToWebAdminInputComponent id="maximumOfferAmount" label="Maximum Offer" disabled={this.state.maximumOfferAmountEnabled} value={this.state.maximumOfferAmount} onChange={this.onTextChange} inputType="money" colSpan="col-8" labelSpan="col-7" controlSpan="col-5" inputClassName={(this.props.rowData?.length > 1 && (this.state.maximumOfferAmountEnabled || this.state.maximumOfferAmount !== "")) ? "admin-site-configuration-dialog-modal-input-bg-yellow" : ""} />
                            <SellToWebAdminToggleComponent id="maximumOfferAmountEnabled" label="Same as Default" label2={this.state.maximumOfferAmountEnabled ? CommonService.formatCurrency_2(this.props.defaultRow.maximumOfferAmount) : ""} disabled={this.checkIfDefault()} onChange={this.onCheckboxChange} checked={this.state.maximumOfferAmountEnabled} colSpan="col-4" />
                        </div>
                        <div className="row">
                            <SellToWebAdminInputComponent id="maximumOdometer" label="Maximum Odometer" disabled={this.state.maximumOdometerEnabled} value={this.state.maximumOdometer} onChange={this.onTextChange} inputType="number" colSpan="col-8" labelSpan="col-7" controlSpan="col-5" inputClassName={(this.props.rowData?.length > 1 && (this.state.maximumOdometerEnabled || this.state.maximumOdometer !== "")) ? "admin-site-configuration-dialog-modal-input-bg-yellow" : ""} />
                            <SellToWebAdminToggleComponent id="maximumOdometerEnabled" label="Same as Default" label2={this.state.maximumOdometerEnabled ? CommonService.formatNumber(this.props.defaultRow.maximumOdometer) : ""} disabled={this.checkIfDefault()} onChange={this.onCheckboxChange} checked={this.state.maximumOdometerEnabled} colSpan="col-4" />
                        </div>
                        <div className="row">
                            <SellToWebAdminInputComponent id="minimumYear" label="Minimum Year" disabled={this.state.minimumYearEnabled} value={this.state.minimumYear} onChange={this.onTextChange} inputType="number" colSpan="col-8" labelSpan="col-7" controlSpan="col-5" inputClassName={(this.props.rowData?.length > 1 && (this.state.minimumYearEnabled || this.state.minimumYear !== "")) ? "admin-site-configuration-dialog-modal-input-bg-yellow" : ""} />
                            <SellToWebAdminToggleComponent id="minimumYearEnabled" label="Same as Default" label2={this.state.minimumYearEnabled ? Math.round(this.props.defaultRow.minimumYear) : ""} disabled={this.checkIfDefault()} onChange={this.onCheckboxChange} checked={this.state.minimumYearEnabled} colSpan="col-4" />
                        </div>
                        <div className="row">
                            <SellToWebAdminInputComponent id="minimumAverageGrade" label="Minimum Average Grade" disabled={this.state.minimumAverageGradeEnabled} value={this.state.minimumAverageGrade} onChange={this.onTextChange} inputType="number" colSpan="col-8" labelSpan="col-7" controlSpan="col-5" inputClassName={(this.props.rowData?.length > 1 && (this.state.minimumAverageGradeEnabled || this.state.minimumAverageGrade !== "")) ? "admin-site-configuration-dialog-modal-input-bg-yellow" : ""} />
                            <SellToWebAdminToggleComponent id="minimumAverageGradeEnabled" label="Same as Default" label2={this.state.minimumAverageGradeEnabled ? CommonService.formatNumber(this.props.defaultRow.minimumAverageGrade) : ""} disabled={this.checkIfDefault()} onChange={this.onCheckboxChange} checked={this.state.minimumAverageGradeEnabled} colSpan="col-4" />
                        </div>
                        <div className="row">
                            <b className="admin-site-configuration-dialog-mmr-calculation-label">Condition Grade Value</b>
                        </div>
                        <div className="row">
                            <SellToWebAdminInputComponent id="gradeLikeNewConditionValue" label="Like New" disabled={this.state.gradeLikeNewConditionValueEnabled} value={this.state.gradeLikeNewConditionValue} onChange={this.onTextChange} inputType="number" colSpan="col-8" labelSpan="col-7" controlSpan="col-5" labelClassName="admin-site-configuration-dialog-mmr-calculation-label" inputClassName={(this.props.rowData?.length > 1 && (this.state.gradeLikeNewConditionValueEnabled || this.state.gradeLikeNewConditionValue !== "")) ? "admin-site-configuration-dialog-modal-input-bg-yellow" : ""} />
                            <SellToWebAdminToggleComponent id="gradeLikeNewConditionValueEnabled" label="Same as Default" label2={this.state.gradeLikeNewConditionValueEnabled ? CommonService.formatNumber(this.props.defaultRow.gradeLikeNewConditionValue) : ""} disabled={this.checkIfDefault()} onChange={this.onCheckboxChange} checked={this.state.gradeLikeNewConditionValueEnabled} colSpan="col-4" />
                        </div>
                        <div className="row">
                            <SellToWebAdminInputComponent id="gradeVeryGoodConditionValue" label="Very Good" disabled={this.state.gradeVeryGoodConditionValueEnabled} value={this.state.gradeVeryGoodConditionValue} onChange={this.onTextChange} inputType="number" colSpan="col-8" labelSpan="col-7" controlSpan="col-5" labelClassName="admin-site-configuration-dialog-mmr-calculation-label" inputClassName={(this.props.rowData?.length > 1 && (this.state.gradeVeryGoodConditionValueEnabled || this.state.gradeVeryGoodConditionValue !== "")) ? "admin-site-configuration-dialog-modal-input-bg-yellow" : ""} />
                            <SellToWebAdminToggleComponent id="gradeVeryGoodConditionValueEnabled" label="Same as Default" label2={this.state.gradeVeryGoodConditionValueEnabled ? CommonService.formatNumber(this.props.defaultRow.gradeVeryGoodConditionValue) : ""} disabled={this.checkIfDefault()} onChange={this.onCheckboxChange} checked={this.state.gradeVeryGoodConditionValueEnabled} colSpan="col-4" />
                        </div>
                        <div className="row">
                            <SellToWebAdminInputComponent id="gradeAcceptableConditionValue" label="Acceptable" disabled={this.state.gradeAcceptableConditionValueEnabled} value={this.state.gradeAcceptableConditionValue} onChange={this.onTextChange} inputType="number" colSpan="col-8" labelSpan="col-7" controlSpan="col-5" labelClassName="admin-site-configuration-dialog-mmr-calculation-label" inputClassName={(this.props.rowData?.length > 1 && (this.state.gradeAcceptableConditionValueEnabled || this.state.gradeAcceptableConditionValue !== "")) ? "admin-site-configuration-dialog-modal-input-bg-yellow" : ""} />
                            <SellToWebAdminToggleComponent id="gradeAcceptableConditionValueEnabled" label="Same as Default" label2={this.state.gradeAcceptableConditionValueEnabled ? CommonService.formatNumber(this.props.defaultRow.gradeAcceptableConditionValue) : ""} disabled={this.checkIfDefault()} onChange={this.onCheckboxChange} checked={this.state.gradeAcceptableConditionValueEnabled} colSpan="col-4" />
                        </div>
                        <div className="row">
                            <SellToWebAdminInputComponent id="gradeMajorIssuesConditionValue" label="Major Issues" disabled={this.state.gradeMajorIssuesConditionValueEnabled} value={this.state.gradeMajorIssuesConditionValue} onChange={this.onTextChange} inputType="number" colSpan="col-8" labelSpan="col-7" controlSpan="col-5" labelClassName="admin-site-configuration-dialog-mmr-calculation-label" inputClassName={(this.props.rowData?.length > 1 && (this.state.gradeMajorIssuesConditionValueEnabled || this.state.gradeMajorIssuesConditionValue !== "")) ? "admin-site-configuration-dialog-modal-input-bg-yellow" : ""} />
                            <SellToWebAdminToggleComponent id="gradeMajorIssuesConditionValueEnabled" label="Same as Default" label2={this.state.gradeMajorIssuesConditionValueEnabled ? CommonService.formatNumber(this.props.defaultRow.gradeMajorIssuesConditionValue) : ""} disabled={this.checkIfDefault()} onChange={this.onCheckboxChange} checked={this.state.gradeMajorIssuesConditionValueEnabled} colSpan="col-4" />
                        </div>
                        <div className="row">
                            <b className="admin-site-configuration-dialog-mmr-calculation-label">Tire Adjustment</b>
                        </div>
                        <div className="row">
                            <SellToWebAdminInputComponent id="tireAdjustmentThreshold" label="Threshold" disabled={this.state.tireAdjustmentThresholdEnabled} value={this.state.tireAdjustmentThreshold} onChange={this.onTextChange} inputType="money" colSpan="col-8" labelSpan="col-7" controlSpan="col-5" labelClassName="admin-site-configuration-dialog-mmr-calculation-label" inputClassName={(this.props.rowData?.length > 1 && (this.state.tireAdjustmentThresholdEnabled || this.state.tireAdjustmentThreshold !== "")) ? "admin-site-configuration-dialog-modal-input-bg-yellow" : ""} />
                            <SellToWebAdminToggleComponent id="tireAdjustmentThresholdEnabled" label="Same as Default" label2={this.state.tireAdjustmentThresholdEnabled ? CommonService.formatCurrency_2(this.props.defaultRow.tireAdjustmentThreshold) : ""} disabled={this.checkIfDefault()} onChange={this.onCheckboxChange} checked={this.state.tireAdjustmentThresholdEnabled} colSpan="col-4" />
                        </div>
                        <div className="row">
                            <b className="admin-site-configuration-dialog-mmr-calculation-child-label">Adjustment when MMR Wholesale Avg</b>
                        </div>
                        <div className="row">
                            <SellToWebAdminInputComponent id="tireAdjustmentBelowThresholdAmount" label="Below Tire Adjustment Threshold" disabled={this.state.tireAdjustmentBelowThresholdAmountEnabled} value={this.state.tireAdjustmentBelowThresholdAmount} onChange={this.onTextChange} inputType="money" colSpan="col-8" labelSpan="col-7" controlSpan="col-5" labelClassName="admin-site-configuration-dialog-mmr-calculation-child-label" inputClassName={(this.props.rowData?.length > 1 && (this.state.tireAdjustmentBelowThresholdAmountEnabled || this.state.tireAdjustmentBelowThresholdAmount !== "")) ? "admin-site-configuration-dialog-modal-input-bg-yellow" : ""} />
                            <SellToWebAdminToggleComponent id="tireAdjustmentBelowThresholdAmountEnabled" label="Same as Default" label2={this.state.tireAdjustmentBelowThresholdAmountEnabled ? CommonService.formatCurrency_2(this.props.defaultRow.tireAdjustmentBelowThresholdAmount) : ""} disabled={this.checkIfDefault()} onChange={this.onCheckboxChange} checked={this.state.tireAdjustmentBelowThresholdAmountEnabled} colSpan="col-4" />
                        </div>
                        <div className="row">
                            <SellToWebAdminInputComponent id="tireAdjustmentAboveThresholdAmount" label="Above Tire Adjustment Threshold" disabled={this.state.tireAdjustmentAboveThresholdAmountEnabled} value={this.state.tireAdjustmentAboveThresholdAmount} onChange={this.onTextChange} inputType="money" colSpan="col-8" labelSpan="col-7" controlSpan="col-5" labelClassName="admin-site-configuration-dialog-mmr-calculation-child-label" inputClassName={(this.props.rowData?.length > 1 && (this.state.tireAdjustmentAboveThresholdAmountEnabled || this.state.tireAdjustmentAboveThresholdAmount !== "")) ? "admin-site-configuration-dialog-modal-input-bg-yellow" : ""} />
                            <SellToWebAdminToggleComponent id="tireAdjustmentAboveThresholdAmountEnabled" label="Same as Default" label2={this.state.tireAdjustmentAboveThresholdAmountEnabled ? CommonService.formatCurrency_2(this.props.defaultRow.tireAdjustmentAboveThresholdAmount) : ""} disabled={this.checkIfDefault()} onChange={this.onCheckboxChange} checked={this.state.tireAdjustmentAboveThresholdAmountEnabled} colSpan="col-4" />
                        </div>
                        <div className="row">
                            <b className="admin-site-configuration-dialog-mmr-calculation-label">Windshield Adjustment</b>
                        </div>
                        <div className="row">
                            <SellToWebAdminInputComponent id="windshieldAdjustmentThreshold" label="Threshold" disabled={this.state.windshieldAdjustmentThresholdEnabled} value={this.state.windshieldAdjustmentThreshold} onChange={this.onTextChange} inputType="money" colSpan="col-8" labelSpan="col-7" controlSpan="col-5" labelClassName="admin-site-configuration-dialog-mmr-calculation-label" inputClassName={(this.props.rowData?.length > 1 && (this.state.windshieldAdjustmentThresholdEnabled || this.state.windshieldAdjustmentThreshold !== "")) ? "admin-site-configuration-dialog-modal-input-bg-yellow" : ""} />
                            <SellToWebAdminToggleComponent id="windshieldAdjustmentThresholdEnabled" label="Same as Default" label2={this.state.windshieldAdjustmentThresholdEnabled ? CommonService.formatCurrency_2(this.props.defaultRow.windshieldAdjustmentThreshold) : ""} disabled={this.checkIfDefault()} onChange={this.onCheckboxChange} checked={this.state.windshieldAdjustmentThresholdEnabled} colSpan="col-4" />
                        </div>
                        <div className="row">
                            <b className="admin-site-configuration-dialog-mmr-calculation-child-label">Adjustment when MMR Wholesale Avg</b>
                        </div>
                        <div className="row">
                            <SellToWebAdminInputComponent id="windshieldAdjustmentBelowThresholdAmount" label="Below Windshield Adjustment Threshold" disabled={this.state.windshieldAdjustmentBelowThresholdAmountEnabled} value={this.state.windshieldAdjustmentBelowThresholdAmount} onChange={this.onTextChange} inputType="money" colSpan="col-8" labelSpan="col-7" controlSpan="col-5" labelClassName="admin-site-configuration-dialog-mmr-calculation-child-label" inputClassName={(this.props.rowData?.length > 1 && (this.state.windshieldAdjustmentBelowThresholdAmountEnabled || this.state.windshieldAdjustmentBelowThresholdAmount !== "")) ? "admin-site-configuration-dialog-modal-input-bg-yellow" : ""} />
                            <SellToWebAdminToggleComponent id="windshieldAdjustmentBelowThresholdAmountEnabled" label="Same as Default" label2={this.state.windshieldAdjustmentBelowThresholdAmountEnabled ? CommonService.formatCurrency_2(this.props.defaultRow.windshieldAdjustmentBelowThresholdAmount) : ""} disabled={this.checkIfDefault()} onChange={this.onCheckboxChange} checked={this.state.windshieldAdjustmentBelowThresholdAmountEnabled} colSpan="col-4" />
                        </div>
                        <div className="row">
                            <SellToWebAdminInputComponent id="windshieldAdjustmentAboveThresholdAmount" label="Above Windshield Adjustment Threshold" disabled={this.state.windshieldAdjustmentAboveThresholdAmountEnabled} value={this.state.windshieldAdjustmentAboveThresholdAmount} onChange={this.onTextChange} inputType="money" colSpan="col-8" labelSpan="col-7" controlSpan="col-5" labelClassName="admin-site-configuration-dialog-mmr-calculation-child-label" inputClassName={(this.props.rowData?.length > 1 && (this.state.windshieldAdjustmentAboveThresholdAmountEnabled || this.state.windshieldAdjustmentAboveThresholdAmount !== "")) ? "admin-site-configuration-dialog-modal-input-bg-yellow" : ""} />
                            <SellToWebAdminToggleComponent id="windshieldAdjustmentAboveThresholdAmountEnabled" label="Same as Default" label2={this.state.windshieldAdjustmentAboveThresholdAmountEnabled ? CommonService.formatCurrency_2(this.props.defaultRow.windshieldAdjustmentAboveThresholdAmount) : ""} disabled={this.checkIfDefault()} onChange={this.onCheckboxChange} checked={this.state.windshieldAdjustmentAboveThresholdAmountEnabled} colSpan="col-4" />
                        </div>
                        <div className="row">
                            <div className="col-8">
                                <div className="row">
                                    <SellToWebAdminToggleComponent id="generalAdjustmentIsDollar" label="General Adjustment:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Percent" label2="Dollar" onChange={this.onCheckboxChange} value={this.state.generalAdjustmentIsDollar} colSpan="col-7" />
                                    <SellToWebAdminInputComponent id="generalAdjustmentValue" placeholder="General Adjustment" disabled={this.state.generalAdjustmentValueEnabled} value={this.state.generalAdjustmentValue} onChange={this.onTextChange} inputType={this.state.generalAdjustmentIsDollar ? "money" : "percent"} colSpan="col-5" controlSpan="col-12" className="admin-site-configuration-dialog-mmr-calculation-control-padding" inputClassName={(this.props.rowData?.length > 1 && (this.state.generalAdjustmentValueEnabled || (this.state.generalAdjustmentValue && this.state.generalAdjustmentValue !== ""))) ? "admin-site-configuration-dialog-modal-input-bg-yellow" : ""} />
                                </div>
                            </div>
                            <SellToWebAdminToggleComponent id="generalAdjustmentValueEnabled" label="Same as Default" label2={this.state.generalAdjustmentValueEnabled ? CommonService.formatCurrency_2(this.props.defaultRow.generalAdjustmentValue) : ""} disabled={this.checkIfDefault()} onChange={this.onCheckboxChange} checked={this.state.generalAdjustmentValueEnabled} colSpan="col-4" />
                        </div>
                        <div className="row">
                            <div className="col-8">
                                <div className="row">
                                    <SellToWebAdminToggleComponent id="accidentAdjustmentIsDollar" label="Accident Adjustment:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Percent" label2="Dollar" onChange={this.onCheckboxChange} value={this.state.accidentAdjustmentIsDollar} colSpan="col-7" />
                                    <SellToWebAdminInputComponent id="accidentAdjustmentValue" placeholder="Accident Adjustment" disabled={this.state.accidentAdjustmentValueEnabled} value={this.state.accidentAdjustmentValue} onChange={this.onTextChange} inputType={this.state.accidentAdjustmentIsDollar ? "money" : "percent"} colSpan="col-5" controlSpan="col-12" className="admin-site-configuration-dialog-mmr-calculation-control-padding" inputClassName={(this.props.rowData?.length > 1 && (this.state.accidentAdjustmentValueEnabled || (this.state.accidentAdjustmentValue && this.state.accidentAdjustmentValue !== ""))) ? "admin-site-configuration-dialog-modal-input-bg-yellow" : ""} />
                                </div>
                            </div>
                            <SellToWebAdminToggleComponent id="accidentAdjustmentValueEnabled" label="Same as Default" label2={this.state.accidentAdjustmentValueEnabled ? CommonService.formatCurrency_2(this.props.defaultRow.accidentAdjustmentValue) : ""} disabled={this.checkIfDefault()} onChange={this.onCheckboxChange} checked={this.state.accidentAdjustmentValueEnabled} colSpan="col-4" />
                        </div>
                        <div className="row">
                            <div className="admin-control col-8">
                                <div className="input-group">
                                    <div className="col-7 admin-label">Enable Offer Calculation:</div>
                                    <div className="col-5">
                                        <SellToWebAdminToggleComponent id="enableOfferCalculation" label="No" label2="Yes" disabled={this.state.enableYMM} value={this.state.enableOfferCalculation} onChange={this.onCheckboxChange} colSpan="col-9" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </SellToWebAdminModalDialogComponent>;
    }
}
