import React, { Component } from "react";

import { CommonService } from "../js_modules/CommonService";

import { SellToWebProgressbarComponent } from "../common/selltowebprogressbar/SelltoWebProgressbarComponent";

import { VehicleDetailsKBBRouteComponent } from "./VehicleDetailsKBBRouteComponent";
import { VehicleDetailsMMRRouteComponent } from "./VehicleDetailsMMRRouteComponent";

export class VehicleDetailsRouteComponent extends Component {
    pageTitle = "Vehicle Details";

    componentDidMount() {
        try {
            this.props.onPageLoad({ pageTitle: this.pageTitle });
            let homeData = CommonService.getSessionStorage("homeData");
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/vehicle-details", pageType: "Consumer", isLoggedIn: "No", properties: { vin: homeData?.vin, vehicleId: homeData?.items[0]?.vehicleId, tenantName: this.props.tenantName, siteName: this.props.siteName } });
        }
        catch (error) {
            console.logError(error, "VehicleDetailsRouteComponent", "componentDidMount");
        }
    }

    render() {
        let headerHtml = CommonService.getSiteAttributeValue(this.props.siteAttributes, "Vehicle Details", "Default", "Header Html")?.Value;
        if (!headerHtml) {
            headerHtml = CommonService.getSiteAttributeValue(this.props.siteAttributes, "Site", "Default", "Header Html")?.Value ?? "";
        }

        let footerHtml = CommonService.getSiteAttributeValue(this.props.siteAttributes, "Vehicle Details", "Default", "Footer Html")?.Value;
        if (!footerHtml) {
            footerHtml = CommonService.getSiteAttributeValue(this.props.siteAttributes, "Site", "Default", "Footer Html")?.Value ?? "";
        }

        return <div className="ac-site ac-vehicledetails">
            <header className="ac-site-header ac-vehicledetails-header" dangerouslySetInnerHTML={{ __html: headerHtml }} />
            <SellToWebProgressbarComponent progressIndex={1} />
            {
                this.props.valuationMethod === "KBB"
                    ? <VehicleDetailsKBBRouteComponent
                        siteAttributes={this.props.siteAttributes}
                        applicationInsights={this.props.applicationInsights}
                    />
                    : <VehicleDetailsMMRRouteComponent
                        siteAttributes={this.props.siteAttributes}
                        applicationInsights={this.props.applicationInsights}
                    />
            }
            <footer className="ac-site-footer ac-vehicledetails-footer" dangerouslySetInnerHTML={{ __html: footerHtml }} />
        </div>;
    }
}