import React, { Component } from "react";

import { CommonService } from "../js_modules/CommonService";

import { TenantSiteOfferCalculationQueryComponent } from "./TenantSiteOfferCalculationQueryComponent";
import { TenantSiteOfferKBBCalculationComponent } from "./TenantSiteOfferKBBCalculationComponent";
import { TenantSiteOfferMMRCalculationComponent } from "./TenantSiteOfferMMRCalculationComponent";

export class TenantSiteOfferCalculationComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingData: false,
            tenants: null,
            tableData: [],
            tableTitle: "",
            paintTable: true,
            mmrTableData: [],
            showAddDialog: false,
            selectedSite: null,
            selectedTenant: null,
            filterValue: ""
        };

        this.onGetClick = this.onGetClick.bind(this);
        this.onCancelAddClick = this.onCancelAddClick.bind(this);
        this.onAddClick = this.onAddClick.bind(this);
        this.onBulkEditClick = this.onBulkEditClick.bind(this);
        this.onLookupClick = this.onLookupClick.bind(this);
        this.onRowSelectChanged = this.onRowSelectChanged.bind(this);

        props.onPageChanged({
            pageTitle: this.pageTitle
        });
    }

    pageTitle = "Offer Calculation";

    componentDidMount() {
        try {
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/Tenant/OfferCalculation", pageType: "Consumer/Admin", isLoggedIn: "Yes", properties: { logon: this.props.logon } });
            CommonService.clientAzureStorageLog("TenantSiteOfferCalculationComponent", "componentDidMount", null, null, null, null, this.props.applicationInsights);
            this.getTenants();
            this.onLookupClick();
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferCalculationComponent", "componentDidMount");
        }
    }

    getTenants() {
        try {
            this.setState({ isLoadingData: true });
            this.props.applicationInsights.trackTrace({ message: "TenantSiteOfferCalculationComponent/getTenants", properties: { logon: this.props.logon } });
            fetch("/TenantSite/GetTenants", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json; charset=utf-8;"
                },
                credentials: "same-origin"
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        isLoadingData: false,
                        tenants: parsedResponse
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/TenantSite/GetTenants", ...parsedError, logon: this.props.logon } });
                                console.logError(parsedError, "TenantSiteOfferCalculationComponent", "getTenants");
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.logError(jsonParseError, "TenantSiteOfferCalculationComponent", "getTenants");
                            });
                    }
                });
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferCalculationComponent", "getTenants");
        }
    }

    loadKBBOfferCalculation() {
        let inputData = {
            siteGuid: this.state.selectedSite.guid
        };
        this.props.applicationInsights.trackTrace({ message: "TenantSiteOfferCalculationComponent/onGetClick", properties: { ...inputData, logon: this.props.logon } });
        fetch("/TenantSite/GetCalculationVariables", {
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8;"
            },
            body: JSON.stringify(inputData),
            credentials: "same-origin"
        })
            .then(response => { if (response.ok) { return response.json() } else { throw response; } })
            .then(parsedResponse => {
                let defaultGeneralAdjustment;
                if (!(parsedResponse.table[0].make && parsedResponse.table[0].model && parsedResponse.table[0].year)) {
                    defaultGeneralAdjustment = parsedResponse.table[0].generalAdjustmentPct;
                }
                parsedResponse.table.forEach((rowData, index) => {
                    rowData.rowClassName = !rowData.enableOfferCalculation ? "" : "";
                    rowData.generalAdjustmentPctcellClassName = rowData.generalAdjustmentPct !== defaultGeneralAdjustment ? "app-sell-trade-offer-calculation-general-adjustment-yellow" : "";
                    rowData.accidentAdjustmentIsDollarLabel = "No";
                    rowData.accidentAdjustmentIsDollarLabel2 = "Yes";
                    rowData.generalAdjustmentIsDollarLabel = "No";
                    rowData.generalAdjustmentIsDollarLabel2 = "Yes";
                    rowData.EnableOfferLabel = "No";
                    rowData.EnableOfferLabel2 = "Yes";
                });

                this.setState({
                    tableData: CommonService.addOrdinalColumn(parsedResponse.table),
                    tableTitle: this.state.selectedTenant.name + " - " + this.state.selectedSite.siteName,
                    paintTable: true,
                    isLoadingData: false
                }, this.setState({ paintTable: false }));
            })
            .catch(notOKResponse => {
                this.setState({
                    isLoadingData: false
                });
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/TenantSite/GetCalculationVariables", ...inputData, ...parsedError, logon: this.props.logon } });
                            console.logError(parsedError, "TenantSiteOfferCalculationComponent", "onGetClick");
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.logError(jsonParseError, "TenantSiteOfferCalculationComponent", "onGetClick");
                        });
                }
            });
    }

    loadMMROfferCalculation() {
        let inputData = {
            siteGuid: this.state.selectedSite.guid
        };
        this.props.applicationInsights.trackTrace({ message: "TenantSiteOfferCalculationComponent/onGetClick", properties: { ...inputData, logon: this.props.logon } });
        fetch("/TenantSite/GetSiteCalculationConfigMMR", {
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8;"
            },
            body: JSON.stringify(inputData),
            credentials: "same-origin"
        })
            .then(response => { if (response.ok) { return response.json() } else { throw response; } })
            .then(parsedResponse => {
                parsedResponse.table.forEach((rowData, index) => {
                    rowData.rowClassName = !rowData.enableOfferCalculation ? "app-sell-trade-offer-calculation-disabled" : "";
                    rowData.accidentAdjustmentIsDollarLabel = "No";
                    rowData.accidentAdjustmentIsDollarLabel2 = "Yes";
                    rowData.generalAdjustmentIsDollarLabel = "No";
                    rowData.generalAdjustmentIsDollarLabel2 = "Yes";
                    rowData.EnableOfferLabel = "No";
                    rowData.EnableOfferLabel2 = "Yes";
                    if (rowData.accidentAdjustmentIsDollar) {
                        rowData.accidentAdjustmentValue_dataType = "money_2";
                    }
                    else {
                        rowData.accidentAdjustmentValue_dataType = "percent_1";
                    }
                    if (rowData.generalAdjustmentIsDollar) {
                        rowData.generalAdjustmentValue_dataType = "money_2";
                    }
                    else {
                        rowData.generalAdjustmentValue_dataType = "percent_1";
                    }
                    if (rowData.maximumOfferAmountIsDefault) {
                        rowData.maximumOfferAmount_cellClassName = "admin-table-cell-bg-grey";
                    }
                    if (rowData.maximumOdometerIsDefault) {
                        rowData.maximumOdometer_cellClassName = "admin-table-cell-bg-grey";
                    }
                    if (rowData.minimumYearIsDefault) {
                        rowData.minimumYear_cellClassName = "admin-table-cell-bg-grey";
                    }
                    if (rowData.minimumAverageGradeIsDefault) {
                        rowData.minimumAverageGrade_cellClassName = "admin-table-cell-bg-grey";
                    }
                    if (rowData.gradeLikeNewConditionValueIsDefault) {
                        rowData.gradeLikeNewConditionValue_cellClassName = "admin-table-cell-bg-grey";
                    }
                    if (rowData.gradeVeryGoodConditionValueIsDefault) {
                        rowData.gradeVeryGoodConditionValue_cellClassName = "admin-table-cell-bg-grey";
                    }
                    if (rowData.gradeAcceptableConditionValueIsDefault) {
                        rowData.gradeAcceptableConditionValue_cellClassName = "admin-table-cell-bg-grey";
                    }
                    if (rowData.gradeMajorIssuesConditionValueIsDefault) {
                        rowData.gradeMajorIssuesConditionValue_cellClassName = "admin-table-cell-bg-grey";
                    }
                    if (rowData.tireAdjustmentBelowThresholdAmountIsDefault) {
                        rowData.tireAdjustmentBelowThresholdAmount_cellClassName = "admin-table-cell-bg-grey";
                    }
                    if (rowData.tireAdjustmentAboveThresholdAmountIsDefault) {
                        rowData.tireAdjustmentAboveThresholdAmount_cellClassName = "admin-table-cell-bg-grey";
                    }
                    if (rowData.tireAdjustmentThresholdIsDefault) {
                        rowData.tireAdjustmentThreshold_cellClassName = "admin-table-cell-bg-grey";
                    }
                    if (rowData.windshieldAdjustmentBelowThresholdAmountIsDefault) {
                        rowData.windshieldAdjustmentBelowThresholdAmount_cellClassName = "admin-table-cell-bg-grey";
                    }
                    if (rowData.windshieldAdjustmentAboveThresholdAmountIsDefault) {
                        rowData.windshieldAdjustmentAboveThresholdAmount_cellClassName = "admin-table-cell-bg-grey";
                    }
                    if (rowData.windshieldAdjustmentThresholdIsDefault) {
                        rowData.windshieldAdjustmentThreshold_cellClassName = "admin-table-cell-bg-grey";
                    }
                    if (rowData.generalAdjustmentValueIsDefault) {
                        rowData.generalAdjustmentValue_cellClassName = "admin-table-cell-bg-grey";
                    }
                    if (rowData.accidentAdjustmentValueIsDefault) {
                        rowData.accidentAdjustmentValue_cellClassName = "admin-table-cell-bg-grey";
                    }
                    if (this.state.mmrTableData.filter(row => row.id === rowData.id && row.isSelected).length === 1) {
                        rowData.isSelected = true;
                    }
                });

                let mmrTableData = CommonService.addOrdinalColumn(parsedResponse.table);
                let tableData = this.searchData(this.state.filterValue, mmrTableData);
                this.setState({
                    mmrTableData: mmrTableData,
                    tableData: tableData,
                    tableTitle: this.state.selectedTenant.name + " - " + this.state.selectedSite.siteName,
                    paintTable: true,
                    isLoadingData: false
                }, this.setState({ paintTable: false }));
            })
            .catch(notOKResponse => {
                this.setState({
                    isLoadingData: false
                });
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/TenantSite/GetCalculationVariables", ...inputData, ...parsedError, logon: this.props.logon } });
                            console.logError(parsedError, "TenantSiteOfferCalculationComponent", "onGetClick");
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.logError(jsonParseError, "TenantSiteOfferCalculationComponent", "onGetClick");
                        });
                }
            });
    }

    onGetClick(selectedTenant, selectedSite) {
        try {
            this.setState({
                isLoadingData: true,
                selectedTenant: selectedTenant,
                selectedSite: selectedSite
            }, () => {
                if (this.state.selectedSite.valuationMethod === "KBB") {
                    this.loadKBBOfferCalculation();
                }
                else {
                    this.loadMMROfferCalculation();
                }
            });
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferCalculationComponent", "onGetClick");
        }
    }

    onAddClick(event) {
        try {
            this.setState({ showAddDialog: true });
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferCalculationComponent", "onAddClick");
        }
    }

    onBulkEditClick(event) {
        try {
            this.setState({ showAddDialog: true });
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferCalculationComponent", "onBulkEditClick");
        }
    }

    onCancelAddClick() {
        try {
            this.setState({ showAddDialog: false });
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferCalculationComponent", "onCancelAddClick");
        }
    }

    onLookupClick(filterValue) {
        try {
            let tableData = this.searchData(filterValue, this.state.mmrTableData);
            this.setState({
                filterValue: filterValue,
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferCalculationComponent", "onLookupClick");
        }
    }

    onRowSelectChanged(selectedRows) {
        try {
            let mmrTableData = this.state.mmrTableData;
            this.setState({ mmrTableData: mmrTableData });
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferCalculationComponent", "onRowSelectChanged");
        }
    }

    searchData(filterValue, mmrTableData) {
        let filterRegex = new RegExp(filterValue, "i");
        let tableData = mmrTableData.filter(rowData => filterRegex.test(rowData.make) || filterRegex.test(rowData.year) || filterRegex.test(rowData.model) || filterRegex.test(rowData.comments));
        mmrTableData.filter(rowData => !(filterRegex.test(rowData.make) || filterRegex.test(rowData.year) || filterRegex.test(rowData.model) || filterRegex.test(rowData.comments)))
            .forEach((row) => { row.isSelected = false });

        return tableData;
    }

    render() {
        return <div className="admin-body admin-fill-content">
            <div className="d-flex flex-column admin-fill-content">
                <TenantSiteOfferCalculationQueryComponent
                    tenants={this.state.tenants}
                    onAddClick={this.onAddClick}
                    onBulkEditClick={this.onBulkEditClick}
                    onGetClick={this.onGetClick}
                    onSiteClick={this.onSiteClick}
                    onTenantClick={this.onTenantClick}
                    isLoadingData={this.state.isLoadingData}
                    isGlobalAdmin={this.props.isGlobalAdmin}
                    applicationInsights={this.props.applicationInsights}
                    logon={this.props.logon}
                    onLookupClick={this.onLookupClick}
                    enableBulkEdit={this.state.mmrTableData.filter((row) => row.isSelected).length > 1}
                />
                <br />
                {
                    this.state.selectedSite
                        ? this.state.selectedSite.valuationMethod === "KBB"
                            ? <TenantSiteOfferKBBCalculationComponent
                                onShowAlert={this.props.onShowAlert}
                                tableTitle={this.state.tableTitle}
                                tableData={this.state.tableData}
                                paintTable={this.state.paintTable}
                                selectedSite={this.state.selectedSite}
                                selectedTenant={this.state.selectedTenant}
                                applicationInsights={this.props.applicationInsights}
                                showAddDialog={this.state.showAddDialog}
                                onGetClick={this.onGetClick}
                                onCancelAddClick={this.onCancelAddClick}
                            />
                            : <TenantSiteOfferMMRCalculationComponent
                                onShowAlert={this.props.onShowAlert}
                                tableTitle={this.state.tableTitle}
                                tableData={this.state.tableData}
                                paintTable={this.state.paintTable}
                                selectedSite={this.state.selectedSite}
                                selectedTenant={this.state.selectedTenant}
                                applicationInsights={this.props.applicationInsights}
                                showAddDialog={this.state.showAddDialog}
                                selectedRows={this.state.mmrTableData.filter((row) => row.isSelected)}
                                onGetClick={this.onGetClick}
                                onCancelAddClick={this.onCancelAddClick}
                                onLookupClick={this.onLookupClick}
                                onRowSelectChanged={this.onRowSelectChanged}
                            />
                        : null
                }
            </div>
        </div>;
    }
}