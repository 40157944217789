import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import moment from "moment";

import { CommonService } from "../js_modules/CommonService";

import { SellToWebProgressbarComponent } from "../common/selltowebprogressbar/SelltoWebProgressbarComponent";
import { SellToWebTopPanelComponent } from "../common/selltowebtoppanel/SellToWebTopPanelComponent";
import { SellToWebRightPanelComponent } from "../common/selltowebrightpanel/SellToWebRightPanelComponent";
import { SellToWebButtonComponent } from "../common/selltowebbutton/SellToWebButtonComponent";
import { SellToWebInputComponent } from "../common/selltowebinput/SellToWebInputComponent";

export class YourDetailsRouteComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            vehicleDetailsData: null,
            firstName: "",
            firstNameChanged: false,
            lastName: "",
            lastNameChanged: false,
            email: "",
            emailChanged: false,
            phone: "",
            phoneChanged: false,
            zipCode: "",
            zipCodeChanged: false,
            isGetOfferClicked: false,
            showSpinner: false,
            redirectTo: ""
        };
        this.onInputValueChange = this.onInputValueChange.bind(this);
        this.onBackButtonClick = this.onBackButtonClick.bind(this);
        this.onGetOfferClick = this.onGetOfferClick.bind(this);
        this.onPhoneBlur = this.onPhoneBlur.bind(this);
        this.onPhoneChange = this.onPhoneChange.bind(this);
        this.onZipCodeChange = this.onZipCodeChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    pageTitle = "Your Details";

    componentDidMount() {
        let vehicleDetailsData = CommonService.getSessionStorage("vehicleDetailsData");
        try {
            this.props.onPageLoad({ pageTitle: this.pageTitle });
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/your-details", pageType: "Consumer", isLoggedIn: "No", properties: { vin: vehicleDetailsData?.vin, vehicleId: vehicleDetailsData?.selectedTrim?.vehicleId, tenantName: this.props.tenantName, siteName: this.props.siteName } });
            if (!vehicleDetailsData) {
                CommonService.clientAzureStorageLog("YourDetailsRouteComponent", "componentDidMount", null, "Redirect to unique-vehicle", null, null, this.props.applicationInsights);
                this.setState({ showSpinner: false, redirectTo: "/unique-vehicle" });
            }
            else {
                CommonService.clientAzureStorageLog("YourDetailsRouteComponent", "componentDidMount", vehicleDetailsData?.selectedTrim?.vehicleId ?? vehicleDetailsData?.selectedTrim?.description.trim + " " + vehicleDetailsData?.vin, null, null, null, this.props.applicationInsights);
                //Get Local Storage Data
                let selectedTrimKey = vehicleDetailsData?.selectedTrim?.vehicleId;
                if (selectedTrimKey === undefined) {
                    selectedTrimKey = vehicleDetailsData?.selectedTrim?.description.trim;
                }
                let lsFirstName = CommonService.getLocalStorage("YourDetails" + selectedTrimKey + "FirstName");
                let lsLastName = CommonService.getLocalStorage("YourDetails" + selectedTrimKey + "LastName");
                let lsEmail = CommonService.getLocalStorage("YourDetails" + selectedTrimKey + "Email");
                let lsPhone = CommonService.getLocalStorage("YourDetails" + selectedTrimKey + "Phone");
                let lsZip = CommonService.getLocalStorage("YourDetails" + selectedTrimKey + "Zip");

                this.setState({
                    firstName: lsFirstName,
                    lastName: lsLastName,
                    email: lsEmail,
                    phone: lsPhone,
                    zipCode: lsZip,
                    vehicleDetailsData: vehicleDetailsData
                });
            }
        }
        catch (error) {
            console.logError(error, "YourDetailsRouteComponent", "componentDidMount", vehicleDetailsData?.selectedTrim?.vehicleId ?? vehicleDetailsData?.selectedTrim?.description.trim + " " + vehicleDetailsData?.vin);
        }
    }

    emailRegex = /^([\w-.+]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
    phoneRegex = /^\d{0,11}$/;
    zipcodeRegex = /^\d{0,5}$/;

    onPhoneBlur(id, value) {
        try {
            let cleaned = ('' + value).replace(/\D/g, '');
            let match = null;
            if (cleaned.toString().length === 10) {
                match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
                value = '(' + match[1] + ') ' + match[2] + '-' + match[3];
                this.setState({
                    [id]: value
                });
            }
            else if (cleaned.toString().length > 10) {
                match = cleaned.match(/^(\d+)(\d{3})(\d{3})(\d{4})$/);
                value = '+' + match[1] + ' (' + match[2] + ') ' + match[3] + '-' + match[4];
                this.setState({
                    [id]: value
                });
            }
        }
        catch (error) {
            console.logError(error, "YourDetailsRouteComponent", "onPhoneBlur", id, value);
        }
    }

    onPhoneChange(id, value) {
        try {
            let cleaned = value.replace(/\D/g, "")
            if (value === "" || this.phoneRegex.test(cleaned)) {
                this.setState({
                    [id]: value,
                    [id + "Changed"]: true
                });
            }
        }
        catch (error) {
            console.logError(error, "YourDetailsRouteComponent", "onPhoneChange", id, value);
        }
    }

    onZipCodeChange(id, value) {
        try {
            if (value === "" || this.zipcodeRegex.test(value)) {
                this.setState({
                    [id]: value,
                    [id + "Changed"]: true
                });
            }
        }
        catch (error) {
            console.logError(error, "YourDetailsRouteComponent", "onZipCodeChange", id, value);
        }
    }

    onInputValueChange(id, value) {
        try {
            this.setState({
                [id]: value,
                [id + "Changed"]: true
            });
        }
        catch (error) {
            console.logError(error, "YourDetailsRouteComponent", "onInputValueChange", id, value);
        }
    }

    handleKeyDown(event) {
        if (event.keyCode === 13) {
            event.preventDefault();
            this.onGetOfferClick();
        }
    }

    onGetOfferClick() {
        try {
            if (!this.state.isGetOfferClicked) {
                this.setState({ isGetOfferClicked: true });
            }

            let inputsValid = this.state.firstName && this.state.lastName && this.state.email
                && this.state.phone && this.state.phone.length >= 10
                && this.state.zipCode && this.state.zipCode.length === 5
                && this.emailRegex.test(this.state.email) === true;
            if (inputsValid) {
                this.setState({ showSpinner: true });
                let vehicleDetailsData = this.state.vehicleDetailsData;
                //Set Local Storage
                let selectedTrimKey = vehicleDetailsData?.selectedTrim?.vehicleId;
                if (!selectedTrimKey) {
                    selectedTrimKey = vehicleDetailsData?.selectedTrim?.description?.trim;
                }
                CommonService.setLocalStorage("YourDetails" + selectedTrimKey + "FirstName", this.state.firstName);
                CommonService.setLocalStorage("YourDetails" + selectedTrimKey + "LastName", this.state.lastName);
                CommonService.setLocalStorage("YourDetails" + selectedTrimKey + "Email", this.state.email);
                CommonService.setLocalStorage("YourDetails" + selectedTrimKey + "Phone", this.state.phone.replace(/\D/g, ''));
                CommonService.setLocalStorage("YourDetails" + selectedTrimKey + "Zip", this.state.zipCode);
                let offerDate = moment.utc().format("yyyyMMDDTHHmmss");
                let offerNumber = offerDate + "." + CommonService.createGuid();
                let vehicleOptions = [];
                let calculateOfferAPIURL = "";
                let inputData = {};
                if (this.props.valuationMethod === "MMR") {
                    calculateOfferAPIURL = "Api/CalculateOffer/v2.0/CalculateOfferInternal";
                    inputData = {
                        source: "DGDGDash",
                        offerNumber: offerNumber,
                        vin: vehicleDetailsData.vin,
                        licensePlate: vehicleDetailsData.licensePlate,
                        licensePlateState: vehicleDetailsData.licensePlateState,
                        odometer: vehicleDetailsData.odometer,
                        valuationZip: "95136",
                        imageUrl: "",
                        colorSimpleTitle: vehicleDetailsData.selectedColor.value ?? "",
                        year: vehicleDetailsData.selectedTrim.description.year,
                        make: vehicleDetailsData.selectedTrim.description.make,
                        model: vehicleDetailsData.selectedTrim.description.model,
                        trim: vehicleDetailsData.selectedTrim.description.trim,
                        exterior: vehicleDetailsData.vehicleConditionData.exterior,
                        interior: vehicleDetailsData.vehicleConditionData.interior,
                        mechanical: vehicleDetailsData.vehicleConditionData.mechanical,
                        tire: vehicleDetailsData.vehicleConditionData.tire,
                        windshield: vehicleDetailsData.vehicleConditionData.windshield,
                        owners: vehicleDetailsData.autocheckData.ownerCount,
                        accidentCount: vehicleDetailsData.autocheckData.accidentCount,
                        autocheckResponse: vehicleDetailsData.autocheckData.autocheckResponse,
                        accidentResponse: vehicleDetailsData.vehicleConditionData.accidentResponse,
                        first: this.state.firstName,
                        last: this.state.lastName,
                        email: this.state.email,
                        phone: this.state.phone.replace(/\D/g, ''),
                        zip: this.state.zipCode,
                    }
                }
                else {
                    calculateOfferAPIURL = "/Api/CalculateOffer/v1.0/CalculateOfferInternal";
                    let trimVehicleOptions = vehicleDetailsData?.selectedTrim?.vehicleOptions;
                    let wheelsTires = [];
                    let selectedVehicleOptions = [];
                    wheelsTires = trimVehicleOptions.filter(vehicleOption => vehicleOption.categoryGroup === "Wheels and Tires");
                    selectedVehicleOptions = trimVehicleOptions.filter(vehicleOption => vehicleOption.isChecked === true);
                    vehicleOptions = trimVehicleOptions.filter(vehicleOption => (vehicleOption.isTypical === true || vehicleOption.isChecked === true) && !(vehicleOption.categoryGroup === "Drivetrain" || vehicleOption.categoryGroup === "Transmission" || vehicleOption.categoryGroup === "Engine"));
                    selectedVehicleOptions.forEach(vehicleOption => {
                        if (wheelsTires.some(wheelsTire => wheelsTire.vehicleOptionId === vehicleOption.vehicleOptionId)) {//Check the User Selected contains Wheel
                            vehicleOptions = vehicleOptions.filter(val => !wheelsTires.includes(val)); // Remove istypical Wheel 
                            vehicleOptions.push(vehicleOption);  //Add User Selected Wheel
                        }
                    });
                    vehicleOptions.push(vehicleDetailsData.selectedEngine, vehicleDetailsData.selectedTransmission, vehicleDetailsData.selectedDriveTrain);
                    if (vehicleDetailsData.selectedExteriorColor) {
                        vehicleOptions.push(vehicleDetailsData.selectedExteriorColor);
                    }
                    inputData = {
                        source: "AcquireCars",
                        offerNumber: offerNumber,
                        vin: vehicleDetailsData.vin,
                        licensePlate: vehicleDetailsData.licensePlate,
                        licensePlateState: vehicleDetailsData.licensePlateState,
                        odometer: vehicleDetailsData.odometer,
                        imageUrl: vehicleDetailsData.imageUrl,
                        colorCode: vehicleDetailsData.selectedKBBVehicleOEMColor.code ?? "",
                        colorTitle: vehicleDetailsData.selectedKBBVehicleOEMColor.title ?? "",
                        colorSimpleTitle: vehicleDetailsData.selectedKBBVehicleOEMColor.simpletitle ? vehicleDetailsData.selectedKBBVehicleOEMColor.simpletitle : vehicleDetailsData.selectedKBBVehicleOEMColor.optionName,
                        colorRgb1: vehicleDetailsData.selectedKBBVehicleOEMColor.rgb1 ?? "",
                        colorRgb2: vehicleDetailsData.selectedKBBVehicleOEMColor.rgb2 ?? "",
                        year: vehicleDetailsData.selectedTrim.yearId,
                        make: vehicleDetailsData.selectedTrim.makeName,
                        makeId: vehicleDetailsData.selectedTrim.makeId,
                        model: vehicleDetailsData.selectedTrim.modelName,
                        modelId: vehicleDetailsData.selectedTrim.modelId,
                        trim: vehicleDetailsData.selectedTrim.trimName,
                        trimId: vehicleDetailsData.selectedTrim.trimId,
                        vehicleId: vehicleDetailsData.selectedTrim.vehicleId.toString(),
                        owners: vehicleDetailsData.autocheckData.ownerCount,
                        accidentCount: vehicleDetailsData.autocheckData.accidentCount,
                        autocheckResponse: vehicleDetailsData.autocheckData.autocheckResponse,
                        accidentResponse: vehicleDetailsData.vehicleConditionData.accidentResponse,
                        paintBody: vehicleDetailsData.vehicleConditionData.paintBody,
                        windowsLights: vehicleDetailsData.vehicleConditionData.windowsLights,
                        wheelsTires: vehicleDetailsData.vehicleConditionData.wheelsTires,
                        upholstery: vehicleDetailsData.vehicleConditionData.upholstery,
                        interiorEquipment: vehicleDetailsData.vehicleConditionData.interiorEquipment,
                        mechanical: vehicleDetailsData.vehicleConditionData.mechanical,
                        keys: vehicleDetailsData.vehicleConditionData.keys,
                        first: this.state.firstName,
                        last: this.state.lastName,
                        email: this.state.email,
                        phone: this.state.phone.replace(/\D/g, ''),
                        zip: this.state.zipCode,
                        vehicleOptions: vehicleOptions
                    }
                }
                this.props.applicationInsights.trackTrace({ message: "YourDetailsRouteComponent/onGetOfferClick", properties: { ...inputData, tenantName: this.props.tenantName, siteName: this.props.siteName } });
                fetch(calculateOfferAPIURL, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json; charset=utf-8;"
                    },
                    body: JSON.stringify(inputData),
                    credentials: "same-origin"
                })
                    .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                    .then(parsedResponse => {
                        if (parsedResponse.status === "Created") {
                            let vehicleOfferData = {
                                offerNumber: parsedResponse.offerNumber,
                                expirationDate: parsedResponse.expirationDate,
                                offer: parsedResponse.offer,
                                lowMiles: parsedResponse.kbbMileageAdjustmentValue,
                                optionAdjustment: parsedResponse.kbbOptionAdjustmentValue
                            }
                            CommonService.setSessionStorage("vehicleOfferData", vehicleOfferData);

                            let offerSession = CommonService.getSessionStorage("offerSession");
                            if (offerSession.vehicleDetail.vehicleOptions) {
                                offerSession.vehicleDetail.vehicleOptions = vehicleOptions;
                            }
                            offerSession.customerInfo = {};
                            offerSession.customerInfo.first = this.state.firstName;
                            offerSession.customerInfo.last = this.state.lastName;
                            offerSession.customerInfo.email = this.state.email;
                            offerSession.customerInfo.phone = this.state.phone.replace(/\D/g, '');
                            offerSession.customerInfo.zip = this.state.zipCode;
                            offerSession.header = {};
                            offerSession.header.offerNumber = parsedResponse.offerNumber;
                            offerSession.header.expirationDate = parsedResponse.expirationDate;
                            offerSession.header.offer = parsedResponse.offer;
                            offerSession.header.kbbMileageAdjustmentValue = parsedResponse.kbbMileageAdjustmentValue;
                            offerSession.header.kbbOptionAdjustmentValue = parsedResponse.kbbOptionAdjustmentValue;
                            offerSession.offer = parsedResponse;
                            CommonService.setSessionStorage("offerSession", offerSession);

                            CommonService.clearLocalStorage(this.state.vehicleDetailsData?.vin, this.state.vehicleDetailsData?.selectedTrim.vehicleId ?? this.state.vehicleDetailsData?.selectedTrim.description.trim);
                            this.setState({ showSpinner: false, redirectTo: "/vehicle-offer" });
                        }
                        else {
                            CommonService.clientAzureStorageLog("YourDetailsRouteComponent", "onGetOfferClick", "fetch CalculateOfferInternal " + this.state.vehicleDetailsData?.selectedTrim?.vehicleId ?? this.state.vehicleDetailsData?.selectedTrim?.description?.trim + " " + this.state.vehicleDetailsData?.vin, "Redirect to unique-vehicle", parsedResponse.errorMessage, null, this.props.applicationInsights);
                            CommonService.clearLocalStorage(this.state.vehicleDetailsData?.vin, this.state.vehicleDetailsData?.selectedTrim?.vehicleId ?? this.state.vehicleDetailsData?.selectedTrim?.description?.trim);
                            this.setState({ showSpinner: false, redirectTo: "/unique-vehicle" });
                        }
                    })
                    .catch(notOKResponse => {
                        if (notOKResponse.status === 500) {
                            notOKResponse.json()
                                .then(parsedError => {
                                    this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/Api/CalculateOffer", ...inputData, ...parsedError, tenantName: this.props.tenantName, siteName: this.props.siteName } });
                                    console.logError(parsedError, "YourDetailsRouteComponent", "onGetOfferClick", this.state.vehicleDetailsData?.selectedTrim?.vehicleId ?? this.state.vehicleDetailsData?.selectedTrim?.description?.trim + " " + this.state.vehicleDetailsData?.vin);
                                })
                                .catch(jsonParseError => {
                                    console.logError(jsonParseError, "YourDetailsRouteComponent", "onGetOfferClick", this.state.vehicleDetailsData?.selectedTrim?.vehicleId ?? this.state.vehicleDetailsData?.selectedTrim?.description?.trim + " " + this.state.vehicleDetailsData?.vin);
                                });
                        }
                    });
            }
            else {
                this.setState({ showSpinner: false });
            }
        }
        catch (error) {
            console.logError(error, "YourDetailsRouteComponent", "onGetOfferClick", this.state.vehicleDetailsData?.selectedTrim?.vehicleId ?? this.state.vehicleDetailsData?.selectedTrim?.description?.trim + " " + this.state.vehicleDetailsData?.vin);
        }
    }

    onBackButtonClick() {
        window.history.go(-1);
    }

    render() {
        if (this.state.redirectTo) {
            return <Navigate to={this.state.redirectTo} />;
        }

        let headerHtml = CommonService.getSiteAttributeValue(this.props.siteAttributes, "Your Details", "Default", "Header Html")?.Value;
        if (!headerHtml) {
            headerHtml = CommonService.getSiteAttributeValue(this.props.siteAttributes, "Site", "Default", "Header Html")?.Value ?? "";
        }

        let footerHtml = CommonService.getSiteAttributeValue(this.props.siteAttributes, "Your Details", "Default", "Footer Html")?.Value;
        if (!footerHtml) {
            footerHtml = CommonService.getSiteAttributeValue(this.props.siteAttributes, "Site", "Default", "Footer Html")?.Value ?? "";
        }

        let bodyHtml = CommonService.getSiteAttributeValue(this.props.siteAttributes, "Your Details", "Default", "Body Html")?.Value ?? "";
        return <div className="ac-site ac-yourdetails">
            <header className="ac-site-header ac-yourdetails-header" dangerouslySetInnerHTML={{ __html: headerHtml }} />
            <SellToWebProgressbarComponent progressIndex={5} />
            <SellToWebTopPanelComponent imageUrl={this.state.vehicleDetailsData?.imageUrl} makePlusModel={this.state.vehicleDetailsData?.makePlusModel} trim={this.state.vehicleDetailsData?.selectedTrim ? (this.state.vehicleDetailsData.selectedTrim.trimName ?? this.state.vehicleDetailsData.selectedTrim.description.trim) : "--"} />
            <section className="consumer-page">
                <section className="consumer-main">
                    <section className="ac-site-body ac-yourdetails-body" dangerouslySetInnerHTML={{ __html: bodyHtml }} />
                    <section className="ac-yourdetails-form">
                        <SellToWebInputComponent id="firstName" value={this.state.firstName} placeholder="First Name"
                            isChecked={this.state.firstName}
                            isError={(this.state.firstNameChanged || this.state.isGetOfferClicked) && !this.state.firstName}
                            onTextChange={this.onInputValueChange} onKeyUp={this.onInputValueChange} onKeyDown={this.handleKeyDown} />
                        <SellToWebInputComponent id="lastName" value={this.state.lastName} placeholder="Last Name"
                            isChecked={this.state.lastName}
                            isError={(this.state.lastNameChanged || this.state.isGetOfferClicked) && !this.state.lastName}
                            onTextChange={this.onInputValueChange} onKeyUp={this.onInputValueChange} onKeyDown={this.handleKeyDown} />
                        <SellToWebInputComponent id="email" value={this.state.email} placeholder="Email" helperText="(This is where we'll send your offer!)"
                            isChecked={this.state.email && this.emailRegex.test(this.state.email)}
                            isError={(this.state.emailChanged || this.state.isGetOfferClicked) && !(this.state.email && this.emailRegex.test(this.state.email))}
                            onTextChange={this.onInputValueChange} onKeyUp={this.onInputValueChange} onKeyDown={this.handleKeyDown} />
                        <SellToWebInputComponent id="phone" value={this.state.phone} placeholder="Phone Number"
                            isChecked={this.state.phone && this.state.phone.length >= 10}
                            isError={(this.state.phoneChanged || this.state.isGetOfferClicked) && (!this.state.phone || this.state.phone.length < 10)}
                            onTextChange={this.onPhoneChange} onKeyUp={this.onPhoneChange} onBlur={this.onPhoneBlur} onKeyDown={this.handleKeyDown} />
                        <SellToWebInputComponent id="zipCode" maxLength={5} value={this.state.zipCode} placeholder="Home Zip Code"
                            isChecked={this.state.zipCode && this.state.zipCode.length === 5}
                            isError={(this.state.zipCodeChanged || this.state.isGetOfferClicked) && !(this.state.zipCode && this.state.zipCode.length === 5)}
                            onTextChange={this.onZipCodeChange} onKeyUp={this.onZipCodeChange} onKeyDown={this.handleKeyDown} />
                        <div className="d-flex justify-content-end">
                            <div style={{ "cursor": "pointer", "fontSize": "12px", "fontWeight": "400", "textDecoration": "underline", "margin": "auto 30px" }} onClick={(event => { window.history.go(-1); })}>
                                <span className="bi bi-chevron-left" style={{ "marginRight": "5px", "verticalAlign": "middle" }} />
                                Go Back
                            </div>
                            <SellToWebButtonComponent onClick={this.onGetOfferClick} showSpinner={this.state.showSpinner} value="Get Offer!" />
                        </div>
                    </section>
                </section>
                <aside className="consumer-summary">
                    <SellToWebRightPanelComponent
                        vin={this.state.vehicleDetailsData?.vin} makePlusModel={this.state.vehicleDetailsData?.makePlusModel} valuationMethod={this.props.valuationMethod}
                        imageUrl={this.state.vehicleDetailsData?.imageUrl} differentColorShown={this.state.vehicleDetailsData?.differentColorShown} odometer={CommonService.formatMoney(this.state.vehicleDetailsData?.odometer)}
                        engine={this.state.vehicleDetailsData?.selectedEngine ? this.state.vehicleDetailsData.selectedEngine.optionName : "--"}
                        transmission={this.state.vehicleDetailsData?.selectedTransmission ? this.state.vehicleDetailsData.selectedTransmission.optionName : "--"}
                        driveTrain={this.state.vehicleDetailsData?.selectedDriveTrain ? this.state.vehicleDetailsData.selectedDriveTrain.optionName : "--"}
                        colorBox={this.state.vehicleDetailsData?.selectedKBBVehicleOEMColor ? (this.state.vehicleDetailsData?.selectedKBBVehicleOEMColor?.colorBox ? CommonService.checkCSSColorSupports(this.state.vehicleDetailsData?.selectedKBBVehicleOEMColor.colorBox) : null) : CommonService.checkCSSColorSupports(this.state.vehicleDetailsData?.selectedColor?.colorBox)}
                        colorName={this.state.vehicleDetailsData?.selectedKBBVehicleOEMColor ? (this.state.vehicleDetailsData?.selectedKBBVehicleOEMColor?.title ? this.state.vehicleDetailsData?.selectedKBBVehicleOEMColor.title : this.state.vehicleDetailsData?.selectedKBBVehicleOEMColor?.optionName) : this.state.vehicleDetailsData?.selectedColor?.value}
                        trim={this.state.vehicleDetailsData?.selectedTrim ? (this.state.vehicleDetailsData.selectedTrim.trimName ?? this.state.vehicleDetailsData.selectedTrim.description.trim) : "--"}
                        minimumCondition={this.state.vehicleDetailsData?.vehicleConditionData.minimumSelectedCondition}
                        siteAttributes={this.props.siteAttributes} applicationInsights={this.state.applicationInsights}
                    />
                </aside>
            </section>
            <footer className="ac-site-footer ac-yourdetails-footer" dangerouslySetInnerHTML={{ __html: footerHtml }} />
        </div>;
    }
}