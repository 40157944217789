import React, { Component } from "react";

export class SellToWebDropdownComponent extends Component {
    constructor(props) {
        super(props);

        this.onSelectChange = this.onSelectChange.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
    }

    onSelectChange(event, item) {
        if (this.props.onSelectChange) {
            this.props.onSelectChange(this.props.id, item, this.props.additionalData);
        }
    }

    onKeyDown(event) {
        if (this.props.onKeyDown) {
            this.props.onKeyDown(event, this.props.id, this.props.additionalData);
        }
    }

    getData(item, field) {
        let data;
        if (field) {
            let dataColumns = field.split(".");
            if (dataColumns.length > 1 && item[dataColumns[0]]) {
                data = item;
                dataColumns.forEach((columnName) => {
                    data = data[columnName];
                });
            }
            else {
                data = item[field];
            }

            if (Array.isArray(data)) {
                data = data.join(", ");
            }
        }

        return data;
    }

    render() {
        let ddlElements = [];
        if (this.props.data) {
            this.props.data.forEach((item, index) => {
                ddlElements.push(<button type="button" key={"ddlItem" + index} disabled={item.disabled} className={"btn btn-link dropdown-item ac-site-dropdown-item " + (this.props.classNameItem ?? "")} onClick={(event) => this.onSelectChange(event, item)} style={{ fontSize: "14px" }}>
                    {
                        item.colorBox
                            ? <div style={{ color: item.colorBox, backgroundColor: item.colorBox, display: "inline-block", lineHeight: "14px", marginRight: "10px", width: "20px", height: "14px", fontSize: "12px" }}>&nbsp;</div>
                            : null
                    }
                    {
                        this.props.itemKey
                            ? this.getData(item, this.props.itemKey)
                            : item
                    }
                </button>);
            });
        }
        return <div className="input-group">
            <div className={"dropdown ac-site-dropdown " + (this.props.className ?? "")}>
                <button className="form-select ac-site-dropdown-button" flag-error={this.props.isError.toString()} type="button" data-bs-toggle="dropdown" aria-expanded="false" style={{ fontSize: "14px" }}>
                    {
                        this.props.colorBox
                            ? <div style={{ color: this.props.colorBox, backgroundColor: this.props.colorBox, display: "inline-block", lineHeight: "14px", marginRight: "10px", width: "20px", height: "14px" }}>&nbsp;</div>
                            : null
                    }
                    {this.props.value}
                </button>
                <div className="dropdown-menu" onKeyDown={(event) => this.onKeyDown(event)}>
                    {ddlElements}
                </div>
            </div>
            {
                this.props.isError
                    ? <span className="fas fa-arrow-left ac-site-error" />
                    : null
            }
            {
                this.props.isChecked
                    ? <span className="fas fa-check ac-site-check" />
                    : null
            }
        </div>;
    }
}